import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OfferDetails from "../components/budgetworrypromise/OfferDetails";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";

const BudgetWorryPromise = () => {
    const breadcrumb = ["Products and Services", "Services", "Budget Worry-free Promise"];
    return (
        <>
            <HeaderOne />
            <BreadcrumbCar breadcrumb={breadcrumb} />
            <LandingSelectCar
                image=""
                title="Budget Worry-free Promise"
                sub="The ultimate in convenience and choice"
            />
            <OfferDetails />
            <FooterOne />
        </>
    );
};

export default BudgetWorryPromise;
