import React from "react";

const Banner = ({ rent, image, type, sub }) => {
  let bannerImage =
    image == ""
      ? "#ed5505"
      : `url(${process.env.PUBLIC_URL}/assets/cars-budget/${image})`;
  return (
    <div
      className="p-5"
      style={{
        backgroundSize: "cover",
        backgroundPositionY: "60%",
        background: `${bannerImage}`,
        position: "relative",
      }}
    >
      <div className="container pt-5 pb-5">
        <h1 className={`${type}`}>{rent}</h1>
      </div>
      {image == "" ? (
        <img
          src={`${process.env.PUBLIC_URL}/assets/cars-budget/car-sitemap.webp`}
          alt="car"
          className="carSiteMap"
        />
      ) : (
        ""
      )}
      {sub != "" ? (
        <div className="container">
          <h4 className="text-dark">Ready, set, rewards</h4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Banner;
