import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import MilesPointsContent from "../components/miles-points/MilesPointsContent";

const MilesPointsPartners = () => {
  const breadcrumb = ["Programs", "Miles, Points & Partners"];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <MilesPointsContent />
      <FooterOne />
    </>
  );
};

export default MilesPointsPartners;
