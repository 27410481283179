import React, { useEffect, useState, Component } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, formatRupiah } from "../../core/functions";
import Axios from "axios";
import { API_URL } from "../../core/constant";
class EXN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extra: [],
      paymentType: false, // pay now
    };
  }

  handleCheckboxChange = (event) => {
    if (event.target.checked) {
      if (!this.state.extra.includes(event.target.value)) {
        this.setState((prevState) => ({
          extra: [...prevState.extra, event.target.value],
        }));
      }
    } else {
      this.setState((prevState) => ({
        extra: prevState.extra.filter((val) => val !== event.target.value),
      }));
    }
  };

  formatDate(va) {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(va);
    return date.toLocaleDateString(undefined, options);
  }

  gotoRevPayLater() {
    this.props.navigate("/reviewandbook", {
      state: {
        paymentType: "paylaters",
        location: this.props.before.location,
        date: this.props.before.pickup_date,
        time: this.props.before.time,
        return_location: this.props.before.return_location,
        return_date: this.props.before.dropoff_date,
        return_time: this.props.before.return_time,
        age: this.props.before.age,
        country: this.props.before.country,
        user_id: this.props.before.user_id,
        offer_code: this.props.before.offer_code,
        vehicle: this.props.before.vehicle,
        extra: this.state.extra,
        price: this.props.price,
        totalPrice: this.props.total,
        tax: this.props.tax,
        total: this.props.total,
        typeOrder: this.props.before.typeOrder,
        taxValue: this.props.taxValue,
        rate_code: this.props.before.rate_code,
      },
    });
  }
  gotoRevPaynow() {
    this.props.navigate("/reviewandbook", {
      state: {
        paymentType: "paynows",
        location: this.props.before.location,
        date: this.props.before.pickup_date,
        time: this.props.before.time,
        return_location: this.props.before.return_location,
        return_date: this.props.before.dropoff_date,
        return_time: this.props.before.return_time,
        age: this.props.before.age,
        country: this.props.before.country,
        user_id: this.props.before.user_id,
        offer_code: this.props.before.offer_code,
        vehicle: this.props.before.vehicle,
        extra: this.state.extra,
        price: this.props.price,
        totalPrice: this.props.total,
        tax: this.props.tax,
        total: this.props.total,
        taxValue: this.props.taxValue,
        typeOrder: this.props.before.typeOrder,
        rate_code: this.props.before.rate_code,
      },
    });
  }

  render() {
    console.log(this.props, "baru");

    let publicUrl = process.env.PUBLIC_URL + "/";
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myBorder = {
      borderBottom: "2px solid rgb(237, 85, 5)",
    };
    const myLinkDis = {
      color: "rgb(168, 168, 168)",
    };
    const myBorderDis = {
      borderBottom: "2px solid rgb(168, 168, 168)",
    };
    const selectLink = {
      border: "2px solid rgb(237, 85, 5)",
      color: "#140a55",
    };
    const btnLink = {
      backgroundColor: "rgb(247, 139, 0)",
      color: "rgb(255, 255, 255)",
      padding: "0px 50px",
      // height: "50px"
    };

    const formattedPrice = Number(this.props.price.split(" ")[1]).toFixed(2);
    const formattedCurrency = this.props.price.split(" ")[0];
    const formattedTax = Number(this.props.tax.split(" ")[1]).toFixed(2);
    const formattedTotal = Number(this.props.total.split(" ")[1]).toFixed(2);
    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <p style={myLinkDis}>1</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLinkDis}>2</p>
                <p style={myBorderDis}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLink}>3 Rental Option</p>
                <p style={myBorder}></p>
              </div>
              <div className="col-sm-3">
                <p style={myLinkDis}>4</p>
                <p style={myBorderDis}></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 border-bottom">
                    <div className="row">
                      <div className="col-sm-6">
                        <p style={myLink}>Pick-Up</p>
                        <p>
                          <b>
                            {localStorage.getItem("nameLocation") !==
                              "undefined" &&
                            localStorage.getItem("nameLocation") !== null
                              ? localStorage.getItem("nameLocation")
                              : ""}
                          </b>
                          <br />
                          {localStorage.getItem("cityLocation") !==
                            "undefined" &&
                          localStorage.getItem("cityLocation") !== null
                            ? localStorage.getItem("cityLocation") + ","
                            : ""}
                          {localStorage.getItem("codeLocation") !==
                            "undefined" &&
                          localStorage.getItem("codeLocation") !== null
                            ? localStorage.getItem("codeLocation") + ","
                            : ""}
                          <br />
                          {localStorage.getItem("addressLine1Location") !==
                            "undefined" &&
                          localStorage.getItem("addressLine1Location") !== null
                            ? localStorage.getItem("addressLine1Location") + ","
                            : ""}
                          {localStorage.getItem("addressLine2Location") !==
                            "undefined" &&
                          localStorage.getItem("addressLine2Location") !== null
                            ? localStorage.getItem("addressLine2Location") + ","
                            : ""}
                          {localStorage.getItem("postalCodeLocation") !==
                            "undefined" &&
                          localStorage.getItem("postalCodeLocation") !== null
                            ? localStorage.getItem("postalCodeLocation") + ","
                            : ""}
                          {localStorage.getItem("countryCodePick") !==
                            "undefined" &&
                          localStorage.getItem("countryCodePick") !== null
                            ? localStorage.getItem("countryCodePick")
                            : ""}
                          <br />
                          <b>
                            {localStorage.getItem("telephoneLocation") !==
                              "undefined" &&
                            localStorage.getItem("telephoneLocation") !== null
                              ? localStorage.getItem("telephoneLocation")
                              : ""}
                          </b>
                          <br />
                          <b>Location Code: </b>{" "}
                          {localStorage.getItem("codeLocation") !==
                            "undefined" &&
                          localStorage.getItem("codeLocation") !== null
                            ? localStorage.getItem("codeLocation")
                            : ""}
                        </p>
                        <p>
                          {this.props.before.time}{" "}
                          {this.formatDate(this.props.before.date)}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <p style={myLink}>Return</p>
                        <p>
                          <b>
                            {localStorage.getItem("nameLocation") !==
                              "undefined" &&
                            localStorage.getItem("nameLocation") !== null
                              ? localStorage.getItem("nameLocation")
                              : ""}
                          </b>
                          <br />
                          {localStorage.getItem("cityLocation") !==
                            "undefined" &&
                          localStorage.getItem("cityLocation") !== null
                            ? localStorage.getItem("cityLocation") + ","
                            : ""}
                          {localStorage.getItem("codeLocation") !==
                            "undefined" &&
                          localStorage.getItem("codeLocation") !== null
                            ? localStorage.getItem("codeLocation") + ","
                            : ""}
                          <br />
                          {localStorage.getItem("addressLine1Location") !==
                            "undefined" &&
                          localStorage.getItem("addressLine1Location") !== null
                            ? localStorage.getItem("addressLine1Location") + ","
                            : ""}
                          {localStorage.getItem("addressLine2Location") !==
                            "undefined" &&
                          localStorage.getItem("addressLine2Location") !== null
                            ? localStorage.getItem("addressLine2Location") + ","
                            : ""}
                          {localStorage.getItem("postalCodeLocation") !==
                            "undefined" &&
                          localStorage.getItem("postalCodeLocation") !== null
                            ? localStorage.getItem("postalCodeLocation") + ","
                            : ""}
                          {localStorage.getItem("countryCodePick") !==
                            "undefined" &&
                          localStorage.getItem("countryCodePick") !== null
                            ? localStorage.getItem("countryCodePick")
                            : ""}
                          <br />
                          <b>
                            {localStorage.getItem("telephoneLocation") !==
                              "undefined" &&
                            localStorage.getItem("telephoneLocation") !== null
                              ? localStorage.getItem("telephoneLocation")
                              : ""}
                          </b>
                          <br />
                          <b>Location Code: </b>{" "}
                          {localStorage.getItem("codeLocation") !==
                            "undefined" &&
                          localStorage.getItem("codeLocation") !== null
                            ? localStorage.getItem("codeLocation")
                            : ""}
                        </p>
                        <p>
                          {this.props.before.reutnr_time}{" "}
                          {this.formatDate(this.props.before.return_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 border-start border-bottom">
                    <div className="row">
                      <div className="col-sm-6">
                        <p>Base Rate</p>
                        <p style={myLink}>Taxes & Fees</p>
                        <p>Estimated Total</p>
                        <p style={myLink}>See Rate Terms</p>
                      </div>
                      <div className="col-sm-6 text-end">
                        <div className="col-sm-6 text-end">
                          <p>
                            {formattedCurrency} {formattedPrice}
                          </p>
                          <p>
                            {formattedCurrency} {formattedTax}
                          </p>
                          <p>
                            <b>
                              {formattedCurrency} {formattedTotal}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row mt-2">
                      <div className="col-sm-3">
                        <img
                          src={this.props.before.vehicle.category.image_url}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-sm-9">
                        <p>
                          <h5 className="text-dark">
                            {this.props.before.vehicle.category.name}
                          </h5>
                        </p>
                        <p>
                          {this.props.before.vehicle.category.make}{" "}
                          {this.props.before.vehicle.category.model}
                        </p>
                        <p>
                          <img src="/assets/images/icon/user.png" />{" "}
                          {this.props.before.vehicle.capacity.seats}
                          {this.props.before.vehicle.capacity &&
                            this.props.before.vehicle.capacity
                              .luggage_capacity &&
                            this.props.before.vehicle.capacity.luggage_capacity
                              .large_suitcase && (
                              <>
                                <img
                                  src="/assets/images/icon/suitcase.png"
                                  className="ms-2"
                                />{" "}
                                {this.props?.before?.vehicle?.capacity
                                  ?.luggage_capacity.large_suitcase ?? 0}
                                L
                                <img
                                  src="/assets/images/icon/suitcase.png"
                                  className="ms-2"
                                />{" "}
                                {this.props?.before?.vehicle?.capacity
                                  ?.luggage_capacity.large_suitcase ?? 0}
                                S
                              </>
                            )}
                        </p>
                        <p>
                          {
                            this.props.before.vehicle.category
                              .vehicle_transmission
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 border-start text-success">
                    <p className="text-end">Inclusions: </p>
                    <ul className="text-end">
                      {this.props.insurance &&
                      this.props.insurance.length > 0 ? (
                        <li>
                          {this.props.insurance.map((is, i) => (
                            <li>{is?.name}</li>
                          ))}
                        </li>
                      ) : (
                        <li></li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-4 py-4 border-top border-bottom">
              <div
                className="col-sm-4 align-self-center text-center mb-4"
                style={{ width: "100%" }}
              >
                <p className="text-dark">
                  <b>IMPORTANT</b>: Cancelling up to 48 hours prior to scheduled
                  pickup time, You will receive a refund of your prepaid amount,
                  minus a <b>IDR 200.000</b> cancellation fee.
                </p>
              </div>
              <div className="col-sm-12 d-flex flex-md-row flex-column justify-content-between align-items-center">
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                  <ul>
                    <li className="me-5">
                      Base rate:{" "}
                      <strong>
                        {formattedCurrency} {formattedPrice}
                      </strong>
                    </li>
                    <li className="me-5">
                      Taxes & Fees:{" "}
                      <strong>
                        {formattedCurrency} {formattedTax}
                      </strong>
                    </li>
                    <li className="me-3">
                      ⁠Estimated Total:{" "}
                      <strong>
                        {formattedCurrency} {formattedTotal}
                      </strong>
                    </li>
                  </ul>
                  <button
                    className="btn py-2 me-3"
                    style={btnLink}
                    disabled={this.props.price === ""}
                    onClick={(e) => {
                      this.gotoRevPayLater();
                    }}
                  >
                    Pay Later
                  </button>
                </div>
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                  <ul>
                    <li className="me-5">
                      Base rate:{" "}
                      <strong>{formatRupiah(this.props.priceIDR)}</strong>
                    </li>
                    <li className="me-5">
                      Taxes & Fees:{" "}
                      <strong>
                        {formatRupiah(
                          Math.round(
                            ((this.props.taxIDR + this.props.priceIDR) *
                              (this.props.taxValue === 0
                                ? 0
                                : this.props.adminTax)) /
                              100
                          )
                        )}
                      </strong>{" "}
                      <span className="text-success"> </span>
                    </li>
                    <li className="me-5">
                      ⁠Estimated Total:{" "}
                      <strong>
                        {formatRupiah(
                          Math.round(
                            this.props.totalIDR +
                              ((this.props.taxIDR + this.props.priceIDR) *
                                (this.props.taxValue === 0
                                  ? 0
                                  : this.props.adminTax)) /
                                100
                          )
                        )}
                      </strong>{" "}
                    </li>
                  </ul>
                  <button
                    className="btn py-2"
                    style={btnLink}
                    disabled={this.props.price === ""}
                    onClick={(e) => {
                      this.gotoRevPaynow();
                    }}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-4">
              <div className="col-sm-12">
                {/* <h5>Equipment & Services</h5> */}
              </div>
            </div>
            {Object.keys(this.props.service).map((is, i) => (
              <div className="row mt-4">
                <div className="col-sm-2">
                  <img
                    className="pe-2"
                    src={
                      publicUrl + "assets/images/resources/protection-ldw.avif"
                    }
                  />
                </div>
                <div className="col-sm-7 align-self-center">
                  <b>{this.props.service[is].name}</b>
                  <p>{this.props.service[is].description}</p>
                </div>
                <div className="col-sm-3 align-self-center">
                  <div class="input-group mb-3">
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text h-100"
                        style={{ backgroundColor: "#f58b00" }}
                      >
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                          id={this.props.service[is].additional_feature_id}
                          value={this.props.service[is].additional_feature_id}
                          onChange={this.handleCheckboxChange}
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      readOnly="true"
                      value={this.props.service[is].basic_price.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )}
                      style={{ backgroundColor: "#f58b00", color: "#fff" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        {/* <section>
                    <div className='container'>
                        <div className='row mt-4'>
                            <div className='col-sm-12'>
                                <h5>Protection & Coverages</h5>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-sm-2'>
                                <img className='pe-2' src={publicUrl+"assets/images/resources/protection-ldw.avif"} />
                            </div>
                            <div className='col-sm-7 align-self-center'>
                                <b>Loss Damage Waiver (LDW)</b>
                                <p>Additional asdd asd ajsd aklsdj</p>
                            </div>
                            <div className='col-sm-3 align-self-center'>
                                <div class="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text h-100" style={{backgroundColor:"#f58b00"}}>
                                            <input type="checkbox" aria-label="Checkbox for following text input" />
                                        </div>
                                    </div>
                                    <input type="text" className="form-control" readOnly="true" value="Rp 10.000" style={{backgroundColor:"#f58b00",color:"#fff"}}/>
                                </div>

                            </div>
                        </div>
                        <div className='col-sm-12 my-4 text-end'>
                            <button className='btnCar py-2'>Continue</button>
                        </div>
                    </div>
                </section> */}
        {/* End Slogan One */}
      </>
    );
  }
}

function EX(props) {
  let navigate = useNavigate();
  const [service, setService] = useState([]);
  const [car, setCar] = useState([]);
  const [spec, setSpec] = useState([]);
  const [price, setPrice] = useState("");
  const [priceIDR, setPriceIDR] = useState("");
  const [insurance, setInsurance] = useState([]);
  const [tax, setTax] = useState("");
  const [fee, setFee] = useState("");
  const [taxIDR, setTaxIDR] = useState("");
  const [total, setTotal] = useState("");
  const [totalIDR, setTotalIDR] = useState("");
  const [adminTax, setAdminTax] = useState(0);
  const [taxValue, setTaxValue] = useState(0);

  // Run Function when pages loaded
  useEffect(() => {
    console.log("PROPS BEFORE 1: " + props.before.location);
    console.log("PROPS BEFORE 2: " + props.before.date);
    console.log("PROPS BEFORE 3: " + props.before.return_location);
    console.log("PROPS BEFORE 4: " + props.before.return_date);
    console.log("PROPS BEFORE 5: " + props.before.vehicle.category.sipp_code);

    Axios.get(
      `${API_URL}/api/booking/tax?productType=Budget`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      setAdminTax(res.data.tax.tax);
    });

    // Axios.post(
    //     // `${API_URL}/api/booking/extra`,
    //     `${API_URL}/api/booking/extra`,
    //     {
    //         date: props.before.date,
    //         return_date: props.before.return_date,
    //         car: props.before.vehicle,
    //     },
    //     {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //         }
    //     },
    // ).then((res) => {
    //     setService(res.data.additional);
    //     setCar(res.data.car);
    //     setSpec(res.data.spec);
    //     setPrice(res.data.price);
    //     setTax(res.data.pajak);
    //     setFee(res.data.fee);
    //     setTotal(res.data.total);
    //     console.log(res);
    // });

    const token = getToken();
    Axios.post(
      "https://stage.abgapiservices.com/cars/catalog/v2/vehicles/rates",
      {
        product: {
          brand: "Budget",
        },
        reservation: {
          pickup_date: props?.before?.pickup_date,
          pickup_location: props.before.location,
          dropoff_date: props?.before?.dropoff_date,
          dropoff_location:
            props.before.return_location == "" ||
            props.before.return_location == null
              ? props.before.location
              : props.before.return_location,
          vehicle_sipp_code: props.before.vehicle.category.sipp_code,
        },
        rate_totals: {
          rate: {
            country_code: props.before.country,
            rate_code:
              props.before.rate_code == ""
                ? props.before.vehicle?.rate_totals?.rate?.rate_code
                : props.before.rate_code,
            discount: {
              code: props.before.vehicle.rate_totals?.rate?.discount?.code,
            },
            membership: {
              code: "",
            },
            coupon: {
              code: "",
            },
          },
        },
      },
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bebe"),
          client_id: "04e19f27",
        },
      }
    )
      .then(function (response) {
        // handle success
        // console.log(response.data);
        return response.data;
      })
      .then(function (data) {
        // handle success
        setTaxValue(data.reservation.rate_totals.totals.taxes_fees_total);
        localStorage.setItem(
          "insurance",
          JSON.stringify(data.reservation.insurance)
        );
        console.log("DATA RATE REQ: " + data.reservation.rate_totals);
        setInsurance(data.reservation.insurance);
        setPrice(
          data.reservation.rate_totals.rate.currency +
            " " +
            data.reservation.rate_totals.totals.vehicle_total
        );
        setTax(
          data.reservation.rate_totals.rate.currency +
            " " +
            data.reservation.rate_totals.totals.taxes_fees_total
        );
        setTotal(
          data.reservation.rate_totals.rate.currency +
            " " +
            data.reservation.rate_totals.totals.reservation_total
        );

        // date now format yyyy-mm-dd
        var dateNow = new Date().toISOString().split("T")[0];
        Axios.get(
          "https://api.fxratesapi.com/convert?from=" +
            data.reservation.rate_totals.rate.currency +
            "&to=IDR&date=" +
            dateNow +
            "&amount=" +
            data.reservation.rate_totals.totals.reservation_total +
            "&format=json"
        )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            setTotalIDR(Math.round(data.result));
          });

        // TAX IDR
        Axios.get(
          "https://api.fxratesapi.com/convert?from=" +
            data.reservation.rate_totals.rate.currency +
            "&to=IDR&date=" +
            dateNow +
            "&amount=" +
            data.reservation.rate_totals.totals.taxes_fees_total +
            "&format=json"
        )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            setTaxIDR(Math.round(data.result));
          });
        // BASE PRICE IDR
        Axios.get(
          "https://api.fxratesapi.com/convert?from=" +
            data.reservation.rate_totals.rate.currency +
            "&to=IDR&date=" +
            dateNow +
            "&amount=" +
            data.reservation.rate_totals.totals.vehicle_total +
            "&format=json"
        )
          .then((res) => {
            return res.data;
          })
          .then((data) => {
            setPriceIDR(Math.round(data.result));
          });

        // setCars2(data.vehicles);
        // setRes(data.reservation);
        // console.log(cars2);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);
  return (
    <EXN
      {...props}
      navigate={navigate}
      service={service}
      car={car}
      price={price}
      tax={tax}
      fee={fee}
      insurance={insurance}
      total={total}
      totalIDR={totalIDR}
      priceIDR={priceIDR}
      taxIDR={taxIDR}
      adminTax={adminTax}
      taxValue={taxValue}
    />
  );
}

export default EX;
