import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OfferDetails from "../components/onewaycarrental/OfferDetails";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";
import Title from '../Title';

const OneWayCarRentalN = () => {
    Title('One-way car rental')
    const breadcrumb = ["Products and Services", "Services", "One Way Car Rental"];
    return (
        <>
            <HeaderOne />
            <BreadcrumbCar breadcrumb={breadcrumb} />
            <LandingSelectCar
                image=""
                title="One Way Car Rental"
                sub="The ultimate in convenience and choice"
            />
            <OfferDetails />
            <FooterOne />
        </>
    );
};

export default OneWayCarRentalN;
