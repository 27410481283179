import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";

const TermConditions = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="container mt-5">
      <button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="text-start mb-4"
      >
        <span className="yellow me-1">Terms and Conditions</span>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/icon/arrow.png`}
          alt="arrow"
          width={10}
          className={`expand ${open ? "active" : ""}`}
        />
      </button>
      <Collapse in={open}>
        <div className="row example-collapse-text mb-5">
          <ul className="listTerm">
            <li>
              The savings of up to 25% applies to Budget leisure rates and is
              applicable only to the time and mileage charges of the rental.
            </li>
            <li>
              Offer does not apply to car group X. Taxes, concession recovery
              fees, vehicle license recovery fee and customer facility charges
              may apply and are extra.
            </li>
            <li>
              Optional products such as LDW ($29.99/day or less) and refueling
              are extra.
            </li>
            <li>
              lease mention BCD <b>X612500</b> to take advantage of this offer.
            </li>
            <li>
              Offer is available for U.S. and Canadian residents only for
              rentals at participating locations in the U.S and Canada.
            </li>
            <li>
              Offer may not be used in conjunction with any other BCD number,
              promotion or offer.
            </li>
            <li>
              Weekly rates require a minimum five day rental period. Weekend
              rate available Thursday noon; car must be returned by Monday 11:59
              p.m., or higher rate will apply.
            </li>
            <li>
              A Saturday night keep and an advance reservation may be required.
            </li>
            <li>
              Offer is subject to vehicle availability at the time of rental and
              may not be available on some rates at some times, including some
              online rates at Budget.com.
            </li>
            <li>Car rental return restrictions may apply.</li>
            <li>Offer subject to change without notice.</li>
            <li>Holiday and other blackout periods may apply.</li>
            <li>
              Renter must meet Budget age, driver and credit requirements.
            </li>
            <li>Minimum age may vary by location.</li>
            <li>
              An additional daily surcharge may apply for renters under 25 years
              old.
            </li>
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

export default TermConditions;
