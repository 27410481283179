import React from "react";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";
import Fa from "../components/faq/Fa";

const FAQ = () => {
    return (
        <>
            <HeaderOne />
            <Fa />
            <FooterOne />
        </>
    )
}

export default FAQ;