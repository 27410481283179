import React from "react";
import { Link, useParams } from "react-router-dom";
import FooterOne from "../../common/footer/FooterOne";
import HeaderOne from "../../common/header/HeaderOne";
import BreadcrumbCar from "../luxury/BreadcrumbCar";

const Hotel = () => {
  const breadcrumb = ["Programs", "Miles, Points & Partners", "Hotel"];

  const params = useParams();

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <div className="container py-4">
        <h1>Hotel Partners</h1>
        <p className="yellow mt-3">
          Get BIG deals and free stuff when you rent from Budget!
        </p>
        <p className="mt-3 text-dark">
          <b>In Summary: </b>
          Rack up points while you enjoy valuable amenities from our hotel
          partners.
        </p>
        <div className="row mt-5">
          <div className="col-md-4 mb-5">
            <p>
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/hotel/best-western-rewards`}
              >
                Best Western Rewards
              </Link>
            </p>
            <p>
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/hotel/choice-privileges`}
              >
                Choice Privileges
              </Link>
            </p>
          </div>
          <div className="col-md-4 mb-5">
            <p>
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/hotel/radisson-rewards`}
              >
                Radisson Rewards
              </Link>
            </p>
            <p>
              <Link
                className="linked"
                to={`${process.env.PUBLIC_URL}/miles-points-partners/hotel/wyndham-rewards`}
              >
                Wyndham Rewards
              </Link>
            </p>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default Hotel;
