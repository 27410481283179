import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Title from "../Title";
import { Link } from "react-router-dom";

export default function SweeterRides() {
  Title("Sweeter Rides & Luxury Rental Cars | Budget Car Rental");
  let publicUrl = process.env.PUBLIC_URL + "/";
  const myBg = {
    backgroundImage:
      "url(" + publicUrl + "assets/images/resources/carguides.avif)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "250px",
  };

  const myLink = {
    color: "rgb(237, 85, 5)",
  };
  return (
    <>
      <HeaderOne />
      <section className="container-fluid mt-5" style={myBg}>
        <div className="row justify-content-md-center">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
            <h1 className="ms-5 ps-4">Popular Rentals & Luxury Vehicles</h1>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#eaeaea" }}>
        <div className="container">
          <div className="row pt-3 pb-3">
            <div className="col-lg-6 col-sm-12 mb-4">
              <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                <div className="row py-4 px-2">
                  <h4 style={{ textAlign: "center" }}>
                    Chrysler Voyager Minivan Rental
                  </h4>
                </div>
                <div className="row px-2">
                  <img
                    className="col-sm-12"
                    src={
                      publicUrl +
                      "assets/images/sweeter-rides/2020-chrysler-voyager-lx-minivan-white_featured_featured.avif"
                    }
                    alt=""
                  />
                </div>
                <div className="row px-2 pb-3">
                  <div className="col-sm-12 text-center">
                    <Link to={"/car-explore"}>
                      <button
                        className="btn align-self-center"
                        style={{
                          backgroundColor: "rgb(0, 40, 95)",
                          color: "#fff",
                          padding: "0px 50px",
                          height: "50px",
                        }}
                      >
                        Rent a Minivan
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 mb-4">
              <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                <div className="row py-4 px-2">
                  <h4 style={{ textAlign: "center" }}>
                    Ford Explorer SUV Rental
                  </h4>
                </div>
                <div className="row px-2">
                  <img
                    className="col-sm-12"
                    src={
                      publicUrl +
                      "assets/images/sweeter-rides/2020-nissan-pathfinder-s-4wd-suv-silver_featured.avif"
                    }
                    alt="2020-nissan-pathfinder-s-4wd-suv-silver_featured"
                  />
                </div>
                <div className="row px-2 pb-3">
                  <div className="col-sm-12 text-center">
                    <Link to={"/car-explore"}>
                      <button
                        className="btn align-self-center"
                        style={{
                          backgroundColor: "rgb(0, 40, 95)",
                          color: "#fff",
                          padding: "0px 50px",
                          height: "50px",
                        }}
                      >
                        Rent a 7-Passenger SUV
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterOne />
    </>
  );
}
