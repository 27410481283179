import React from "react";
import { Link } from "react-router-dom";

const CardPopular = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="cardPopular">
      <div className="row pt-4">
        <div className="col">
          <span className="me-2 text-white px-2 py-1 bg-orange rounded-circle">
            1
          </span>
          <span style={{ color: "#ed5505" }}>Golden Nugget Hotel</span>
        </div>
      </div>
      <div className="row py-4 ps-4 text-dark">
        <div className="col">
          <p>
            <img src={publicUrl + "assets/images/icon/location.png"} />
            <b className="ms-1">Address:</b>
          </p>
          <p className="ms-3">129 E Fremont St</p>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p>
                <img src={publicUrl + "assets/images/icon/telephone.png"} />
                <b className="ms-1">Phone:</b>
              </p>
            </div>
            <div className="col-12">
              <p className="ms-3">(1) 702-568-2212</p>
            </div>
            <div className="col-12">
              <p>
                <img
                  src={publicUrl + "assets/images/icon/shopping-store.png"}
                />
                <b className="ms-1">Location Type:</b>
              </p>
            </div>
            <div className="col-12">
              <p className="ms-3">Licensee</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p>
                <img src={publicUrl + "assets/images/icon/clock.png"} />
                <b className="ms-1">Hours of Operation:</b>
              </p>
            </div>
            <div className="col-12">
              <p className="ms-3">Sun - Sat 7:30 AM - 4:30 PM</p>
            </div>
            <div className="col-12">
              <Link className="linked text-decoration-none">
                <b>Holiday Hours</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="col">
          <button className="btnCar">Make a Reservation</button>
        </div>
      </div>
    </div>
  );
};

export default CardPopular;
