import React from "react";
import { Link } from "react-router-dom";

const Corporate = () => {
  return (
    <div className="container py-4">
      <p className="mb-5">
        Budget makes corporate contributions that support important service
        initiatives throughout America's communities.
      </p>
      <p className="mb-5">
        To maximize our corporate contributions, we concentrate our
        philanthropic efforts on national organizations that serve the needs of
        the communities in which we operate. We welcome your requests for
        donations to your organization.Due to the enormous volume of donation
        requests we receive, it is not possible to respond to every single
        inquiry.
      </p>
      <Link className="btnCar d-inline-block">Make a Reservation</Link>
    </div>
  );
};

export default Corporate;
