import React from "react";
import Card from "../../common/card/Card";
import Header from "../../common/header/Header";

const MilesPointsContent = () => {
  return (
    <>
      <Header title="Budget Rewards: Miles, Points & Partners" />
      <div className="carsContainer">
        <div className="container">
          <div className="row mt-4 py-5">
            <Card
              title="Airlines"
              description="Frequent flyers with our airline partners like JetBlue and Southwest stand to earn extra airline miles and other Budget rewards when you rent from us during your travels."
              link="miles-points-partners/airlines"
            />
            <Card
              title="Hotel"
              description="Accumulate hotel loyalty points in your sleep and enjoy extra savings on your Budget rental, thanks to our hotel partner deals."
              link="miles-points-partners/hotel"
            />
            <Card
              title="Other Partners"
              description="Discover special offers from Budget Car Rental partners like Aeroplan, Marriott Vacation Club, and Upromise. Enhance your next trip with these deals."
              link="miles-points-partners/other-partners"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MilesPointsContent;
