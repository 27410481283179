import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "../../core/constant";

const SignModal = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const email_change = (event) => setEmail(event.target.value);
  const password_change = (event) => setPassword(event.target.value);

  const submit = () => {
    // Axios.get("https://backend.avis-id.com/public/sanctum/csrf-cookie",{withCredentials: true}).then((resp)=>{
    Axios.post(
      `${API_URL}/api/auth/signin`,
      // `${API_URL}/api/auth/signin`,
      {
        email: email,
        password: password,
      },
      {
        // withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).then((res) => {
      console.log(res.data);
      if (res.data.status == false) {
        alert(res.data.msg);
      } else {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("customer_id", res.data.customer_id);
        const expires = new Date();
        expires.setTime(expires.getTime() + 21600 * 1000);
        document.cookie = `subscribe=true;expires=${expires.toUTCString()};path=/`;
        window.location.reload();
      }
    });
    // });
  };
  return (
    <>
      <div>
        {/* <a href="#" className="main-menu__search search-toggler icon-search" variant="primary" onClick={handleShow}></a> */}
        <button
          className="main-menu__search search-toggler yellow"
          variant="primary"
          onClick={handleShow}
        >
          <b>Sign In</b>
        </button>
      </div>
      <div className="search-popup">
        <Modal
          show={show}
          onHide={handleClose}
          className="search-popup__overlay search-toggler"
        >
          <div className="search-popup__content">
            <form action="#">
              <div className="container p-5">
                <div className="row">
                  <div className="col-xl-9">
                    <h4>Sign In Into Your Budget Account</h4>
                  </div>
                  <div className="col-xl-3 text-end">
                    <span
                      className="main-menu__search search-toggler"
                      style={{
                        color: "var(--thm-base)",
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    >
                      <b>Cancel</b>
                    </span>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xl-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={(e) => email_change(e)}
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xl-12">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password (Case Sensitive)"
                      name="password"
                      onChange={(e) => password_change(e)}
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xl-6 text-dark">
                    <b>Remember Me</b>
                  </div>
                  <div className="col-xl-6 text-end">
                    <input type="checkbox" />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xl-12">
                    This site is protected by reCAPTCHA Enterprise and the
                    Google Privacy Policy and Terms of Service apply.
                  </div>
                </div>
                <div className="row pt-3">
                  <button
                    className="ml-2 mr-2 col-xl-12 thm-btn comment-form__btn"
                    type="button"
                    onClick={() => submit()}
                  >
                    Sign In
                  </button>
                </div>
                <div className="row pt-3">
                  {/* <div className="col-xl-6 text-center border-right">
                    <a
                      href="#"
                      className="main-menu__search search-toggler"
                      style={{ color: "var(--thm-base)" }}
                    >
                      <b>Forgot Customer ID?</b>
                    </a>
                  </div> */}
                  <div className="col-xl-6 text-center">
                    <button
                      href="#"
                      className="main-menu__search search-toggler"
                      style={{ color: "var(--thm-base)" }}
                    >
                      <b>Forgot Password?</b>
                    </button>
                  </div>
                </div>
                <div className="row pt-5">
                  <Link to="/register" className="col-xl-12 border-bottom p-2">
                    <div className="row">
                      <div
                        className="col-2 text-center icon align-self-center"
                        style={{
                          color: "var(--thm-base)",
                          fontsize: "xx-large",
                        }}
                      >
                        <span className="icon-user"></span>
                      </div>
                      <div className="col-10 text-start">
                        <b>Create An Account</b>
                        <br />
                        It's easy and only takes a minute
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="row pt-5">
                  <a href="#" className="col-xl-12 border-bottom p-2">
                    <div className="row">
                      <div
                        className="col-2 text-center icon align-self-center"
                        style={{
                          color: "var(--thm-base)",
                          fontsize: "xx-large",
                        }}
                      >
                        <span className="icon-plus"></span>
                      </div>
                      <div className="col-10 text-start">
                        <b>More Sign In Options</b>
                      </div>
                    </div>
                  </a>
                </div> */}
              </div>
              {/* <label for="search" className="sr-only">search here</label>
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn2">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </button> */}
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SignModal;
