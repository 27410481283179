import React from 'react';
import {Link} from 'react-router-dom';

export default class Bc extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/';
        const myBg = {
            backgroundImage: "url("+publicUrl+"assets/images/resources/businessrentals.avif)",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: "250px"
        }
        const myText = {
            color: "#000",
        }
        const myLink = {
            color: "rgb(237, 85, 5)",
            textDecoration: "underline"
        }
        const myPara = {
            lineHeight: "1.5",
            fontSize: "15px"
        }
        const myUl = {
            paddingLeft: "50px"
        }
        const myH3 = {
            color: "rgb(0, 40, 95);"
        }
        return (
            <>
                {/* Start Slogan One */}
                <section className='border-top border-bottom'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-12 pt-2'>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Library</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='container-fluid' style={myBg}>
                    <div className='row justify-content-md-center'>
                        <div className='col-lg-7 col-md-9 col-sm-12 mt-5'>
                            <h1 className=''>Budget Business Program</h1>
                            <h3 className='text-dark mt-2' style={myText}>Ready, set, rewards</h3>
                        </div>
                    </div>
                </section>

                <section className='container-fluid mt-3' style={myText}>
                    <div className='row justify-content-md-center'>
                        <div className='col-lg-7 col-md-9 col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <b>Get more for your small business.</b>
                                </div>
                                <div className='col-sm-6'>
                                    <b>Already a member?</b><a href='#' style={myLink}>Log In Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-md-center mt-3'>
                        <div className='col-lg-7 col-md-9 col-sm-12 border-bottom' style={myPara}>
                            <p className='mb-3'>The Budget Business Program is an easy-to-join program with endless travel perks for you and your company. As members, you save money on every rental, earn credits and so much more!</p>
                            <p className='mb-3'>Enroll today to get your Budget Customer Discount (BCD) number to share with your company's travelers, and start saving and earning.</p>
                            <p className='mb-3'>Some of our Budget Business Program benefits include:</p>
                            <ul style={myUl}>
                                <li>- Up to 30% off base rates.</li>
                                <li>- $3 reward credit for every qualifying rental day.</li>
                                <li>- Access to the Budget Business Program member-only website.</li>
                                <li>- Complimentary Fastbreak membership to allow you skip the counter at pickup.</li>
                                <li>- A dedicated support team.</li>
                            </ul>
                            <p className='mb-3'>Enroll now to start saving and earning immediately.</p>
                            <p className='mb-3'>View <a href="#" style={myLink}>Program Terms and Conditions.</a></p>
                        </div>
                    </div>
                    <div className='row justify-content-md-center mt-3 border-bottom'>
                        <div className='col-lg-7 col-md-9 col-sm-12'>
                            <h3 style={myH3} className="mb-3">Name</h3>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>First Name</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Last Name</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Job Title <small><i>(optional)</i></small></label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-md-center mt-3 border-bottom'>
                        <div className='col-lg-7 col-md-9 col-sm-12'>
                            <h3 style={myH3} className="mb-3">Contact Infromation</h3>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Email</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Re-enter Email</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Phone</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <div className='col-sm-12'>
                                    <p>Your Budget Corporate Discount (BCD) number will be sent to the email provided.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-md-center mt-3 border-bottom'>
                        <div className='col-lg-7 col-md-9 col-sm-12'>
                            <h3 style={myH3} className="mb-3">Communication Preferences </h3>
                            <div className='row mb-3'>
                                <div className='col-sm-6'>
                                    <input type="radio" name='cp' />Yes, I want to receive updates from Budget about products, services, special offers and important updates regarding the Budget Business Program. 
                                </div>
                                <div className='col-sm-6'>
                                    <input type="radio" name='cp' />No, I do not want to receive promotional e-mail offers at this time. 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='row justify-content-md-center mt-3 border-bottom'>
                        <div className='col-lg-7 col-md-9 col-sm-12'>
                            <h3 style={myH3} className="mb-3">Company Information </h3>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Business Name </label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Address Line 1</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Address Line 2 <small><i>(optional)</i></small></label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>City</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>State</label>
                                <select type="text" className='col-sm-9 wide'>
                                    <option>Please Select One</option>
                                    <option>Indonesia</option>
                                    <option>South Korea</option>
                                    <option>China</option>
                                    <option>Japan</option>
                                    <option>Siangapore</option>
                                </select>
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Zip</label>
                                <input type="text" className='col-sm-9 wide' />
                            </div>
                            <div className='row mb-3'>
                                <label className='col-sm-3'>Country</label>
                                <select type="text" className='col-sm-9 wide'>
                                    <option>Please Select One</option>
                                    <option>Indonesia</option>
                                    <option>South Korea</option>
                                    <option>China</option>
                                    <option>Japan</option>
                                    <option>Siangapore</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-md-center mt-3 border-bottom'>
                        <div className='col-lg-7 col-md-9 col-sm-12'>
                            <div className='row mb-3'>
                                <a href="#" className='col-sm-6' style={myLink}>Cancel </a>
                                <div className='col-sm-6 text-end' >
                                    <button className='btn' style={{backgroundColor:"rgb(0, 40, 95)", color:"#fff", padding:"0px 50px", height:"50px"}}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Slogan One */}
            </>
        )
    }
}