import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import TravelAgentContent from "../components/travel-agent/TravelAgentContent";

const TravelAgent = () => {
  const breadcrumb = ["Programs", "Travel Agent Program"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <TravelAgentContent />
      <FooterOne />
    </>
  );
};

export default TravelAgent;
