import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderAlt from "../common/header/HeaderAlt";
import MK from "../components/makereservation/MK";

const MakeReservation = () => {
  return (
    <>
      <HeaderAlt />
      <MK />
      <FooterOne />
    </>
  );
};

export default MakeReservation;
