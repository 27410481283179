import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Commissions from "../components/travel-agent/Commissions";

const CommissionPolicy = () => {
  const breadcrumb = ["Programs", "Travel Agent Program", "Commissions Policy"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Commissions />
      <FooterOne />
    </>
  );
};

export default CommissionPolicy;
