import React, { useState } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../../core/constant";

const ModalSubscribe = () => {
  const [show, setShow] = useState(true);
  const [email, setEmail] = useState("");
  const handleClose = () => setShow(false);
  function handleSubmit() {
    if (email) {
      document.cookie = `subscribe=true;path=/`;

      Axios.post(
        `${API_URL}/api/email/subscribe`,
        {
          emailForm: email,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          alert(
            "Thank you for subscribing to our newsletter. You will receive a discount code soon."
          );
        })
        .catch((error) => {
          alert(
            "Thank you for subscribing to our newsletter. You will receive a discount code soon."
          );
        });

      handleClose();
    }
    setEmail("");
  }

  function handleReject() {
    const expires = new Date();
    expires.setTime(expires.getTime() + 21600 * 1000);
    document.cookie = `subscribe=true;expires=${expires.toUTCString()};path=/`;
    handleClose();
  }
  return (
    <>
      <div className="search-popup">
        <Modal
          show={show}
          onHide={handleClose}
          className="search-popup__overlay search-toggler"
        >
          <div className="search-popup__content">
            <form>
              <div className="container p-5">
                <div className="row">
                  <div className="col-xl-9">
                    <h5>Subscribe to our newsletter here!</h5>
                  </div>
                  <div className="col-xl-3 text-end">
                    <button
                      className="main-menu__search search-toggler"
                      style={{ color: "var(--thm-base)" }}
                      onClick={handleReject}
                    >
                      <b>Cancel</b>
                    </button>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xl-12">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <button
                    className="ml-2 mr-2 col-xl-12 thm-btn comment-form__btn"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </button>
                </div>

                <div className="row pt-3">
                  <button
                    className="ml-2 mr-2 col-xl-12 thm-btn comment-form__btn"
                    type="button"
                    onClick={handleReject}
                  >
                    No, I don't want to subscribe
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ModalSubscribe;
