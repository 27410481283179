import React from "react";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <>
      <footer className="footer-one">
        <div className="footer-one__top" style={{ padding: "30px 0 30px" }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-one__top-inner">
                  <div className="row">
                    <div className="col-xl-3">
                      <div className="footer-widget__about-social-link">
                        <div className="select-box">
                          <select className="form-selectmenu wide">
                            <option>English</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div
                className="col-xl-12"
                style={{ borderBottom: "1px solid rgb(97, 97, 97)" }}
              ></div>
            </div>
          </div>
        </div>

        {/* Start Footer One Bottom */}
        <div
          className="footer-one__bottom clearfix"
          style={{ backgroundColor: "#140a55" }}
        >
          <div className="container">
            <div className="d-flex justify-content-between align-items-center flex-lg-row flex-column-reverse">
              <div className="mb-3">
                <p className="text-white" style={{ fontSize: "10px" }}>
                  <span>&copy; 2023 Budget Rent A Car System, Inc. | </span>
                  <Link className="text-white">Terms of use</Link>
                  <span> | </span>
                  <Link className="text-white">Privacy Notice</Link>
                  <span> | </span>
                  <Link className="text-white">California Privacy Notice</Link>
                </p>
              </div>
              <div className="mb-3">
                <Link>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/cars-budget/footer-norton.webp`}
                    alt="norton"
                    width={80}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer One Bottom */}
      </footer>
    </>
  );
};

export default FooterThree;
