import React from "react";
import { Link } from "react-router-dom";

const Mission = () => {
  return (
    <div className="container py-4">
      <p className="mb-5">
        Day after day, Budget employees and operators at more than 3,000
        locations around the world help support our vision, mission and values.
      </p>
      <h5 className="mb-3">Vision</h5>
      <p className="mb-3">
        We will be a growth-oriented car rental company by efficiently serving
        value-conscious customers for all of their car rental occasions.
      </p>
      <h5 className="mb-3">Mission</h5>
      <p className="mb-3">
        We will consistently deliver a quality product, friendly service and
        great value that make customers confident that Budget is their best car
        rental choice.
      </p>
      <h5 className="mb-3">Values</h5>
      <p className="text-dark">
        <b>A commitment to great value for customers:</b>
      </p>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>
          We provide great service at a great price that clearly demonstrates to
          our customers that they received the best car rental value.
        </li>
        <li>
          We use new ideas and innovations to enhance service and increase
          customer satisfaction.
        </li>
        <li>
          We solve problems creatively, and take action on behalf of our
          customers.
        </li>
      </ul>
      <p className="text-dark">
        <b>A focus on quality and service:</b>
      </p>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>
          We deliver quality work for the benefit of our customers and
          coworkers.
        </li>
        <li>
          We provide consistent and dependable service that exceeds expectations
          and creates loyal customers.
        </li>
        <li>
          We care each day about how our job affects the quality of the Budget
          product
        </li>
      </ul>
      <p className="text-dark">
        <b>A deep pride in who we are and how we work:</b>
      </p>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>
          We always keep the highest personal standards of integrity and
          honesty.
        </li>
        <li>We assist others on our team and throughout the company.</li>
        <li>
          We share our talents, skills and knowledge to improve team
          performance.
        </li>
        <li>We communicate openly and honestly, and share great ideas.</li>
      </ul>
      <p className="text-dark">
        <b>A recognition that profitability fuels our growth:</b>
      </p>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>
          We understand the importance of growing the profitability of our
          location and our business overall.
        </li>
        <li>
          We inspire others to elevate their performance and contribute to the
          company's success.
        </li>
        <li>
          We continue learning and developing new skills that help us build our
          bottom line and set the stage for continued growth.
        </li>
      </ul>
      <p className="text-dark">
        <b>A dedication to being a great place to work:</b>
      </p>
      <ul className="similarCarOpt ps-5 mb-3">
        <li>
          We believe in creating a fun and friendly car rental experience for
          employees and customers alike.
        </li>
        <li>
          We maintain an upbeat work environment that is respectful of and
          welcoming to all employees and customers of all backgrounds.
        </li>
        <li>
          We are committed to a well-maintained, safe and presentable workplace.
        </li>
        <li>
          We show caring for our communities and encourage employee involvement
          in civic and charitable activities.
        </li>
      </ul>
      <Link className="btnCar mt-4 d-inline-block">Make a Reservation</Link>
    </div>
  );
};

export default Mission;
