import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import VMC_VIEW from "../components/viewmodifycancel/vmc_view";
import Title from "../Title";
import { useNavigate, useLocation } from "react-router-dom";

const ViewBooking = () => {
    Title("Reservation Confirmation/Cancellation");
    const navigate = useNavigate();
    const { state } = useLocation();
    return (
        <>
            <HeaderOne />
            <VMC_VIEW navigate={navigate} bookingData={state?.bookingData} bookingDataApi={state?.bookingDataApi} />
            <FooterOne />
        </>
    );
};

export default ViewBooking;
