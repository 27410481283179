import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Other from "../components/miles-points/Other";

const OtherPartners = () => {
  const breadcrumb = ["Programs", "Miles, Points & Partners", "Other Partners"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Other />
      <FooterOne />
    </>
  );
};

export default OtherPartners;
