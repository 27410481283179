import React from "react";

const OfferDetails = () => {
    return (
        <div className="container">
            <hr />
            <h3>Hire from one location and return it to another!</h3>
            <p className="mt-3 mb-3">
                Planning the road trip of a lifetime? Or perhaps you just want to collect from the airport and return nearer to your hotel? Whatever your reason, at many Budget locations you can rent a vehicle from one branch and return to another (one-way rental), giving you the flexibility to plan your holiday or business trip to suit you.
            </p>
            <b className="mt-3">What is a One-Way Rental?</b>
            <p className="mt-3 mb-3">
                One way car hire from Budget allows you to pick up a car at one station and return it to another. This is a perfect product for people who won't be returning to an area but need to get to another.
            </p>
            <b className="mt-3">How can I book a One-Way Rental?</b>
            <p className="mt-3 mb-3">
                To start your one-way rental, enter your rental pick-up location, date and time in the booking panel on the homepage. Then simply tick the 'Returning somewhere different' checkbox and enter your return location and get a quote.
            </p>
            <p className="mt-3 mb-3">
                International one-way rentals are sometimes available between different countries. For more information please call our reservations team and they will be happy to help.
            </p>
            <b className="mt-3">How much does a One-Way Rental cost?</b>
            <p className="mt-3 mb-3">
                One way car hire is available from most Budget locations, although an additional fee may be payable depending upon your chosen pick-up and drop-off locations. Fees will vary by country.
            </p>
            <p className="mt-3 mb-3">
                The price quoted online will automatically include your vehicle rental cost and any additional one-way rental charges (if applicable). Rentals that are collected / returned within the same city area are not usually affected by one-way charges, but please check at the time of booking.
            </p>
            <b className="mt-3">Where is One-Way Rental available?</b>
            <ol className="mb-3">
                <li>•	One-way car hire is most common between branches within the same country. Within mainland Europe, one-way rentals are available between certain countries. Please note that whilst UK pick-ups can travel to Europe, one-way rentals out of the UK are not allowed.</li>
                <li>
                    •	One-Way Rentals in the Americas <br />Due to the Coronavirus pandemic, please refer to the Mexico or Canada travel advisory to ensure international travel is permitted.<br />The Budget rules for one-way rentals from the U.S. to other countries are as follows:
                    <ol>
                        <li>+	One-way rentals cannot be driven into Mexico. But you may be able to drive a USA rental into Mexico, if returning to the US—See terms for US to Mexico rentals.</li>
                        <li>+	Some Budget locations allow for one-way rental travel into Canada. Check the terms and conditions for your rental location.</li>
                        <li>+	One-way rentals to Canada are dependent on availability and must be requested in advance.</li>
                    </ol>
                </li>
            </ol>
            <b className="mt-3">Requesting a New Drop-Off Location After Car Pickup</b>
            <p className="mt-3 mb-3">
                Budget understands that your plans might change after your rental period begins. If you decide you want to pick up at one location and drop off at another, you must contact a Budget location in advance to check in case restrictions apply.. Our location staff will let you know if any return restrictions apply.
            </p>
            <p className="mt-3 mb-5">
                Please be aware you must contact us before returning your rental to a different drop-off location. You’ll be charged for an Unauthorized Return Fee if you don’t contact us beforehand. You may be charged a higher amount based on the distance between the pickup location and drop-off location specified on your reservation.
            </p>
        </div>
    );
};

export default OfferDetails;
