import React, { useEffect, useState } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import LandingSelectCar from "../components/road/LandingSelectCar";
import Title from "../Title";
import ModalSubscribe from "../common/header/ModalSubscribe";
import { useNavigate } from "react-router-dom";

const HomeDefault = () => {
  const [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();
  Title("Budget Car Rental");
  useEffect(() => {
    const cookies = document.cookie.split(";");
    let subscribeCookieExists = false;
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith("subscribe=true")) {
        subscribeCookieExists = true;
        break;
      }
    }
    const tokenFrmLocalstorage = localStorage.getItem("token");
    if (!subscribeCookieExists && !tokenFrmLocalstorage) {
      setShowModal(true);
    }
  }, []);
  return (
    <>
      {showModal && <ModalSubscribe />}
      <HeaderOne />
      <LandingSelectCar
        image="home-banner.webp"
        title="Get Your Car Now"
        sub=""
        navigate={navigate}
        page=""
      />
      <FooterOne />
    </>
  );
};

export default HomeDefault;
