import React from "react";
import { Link } from "react-router-dom";

export default class Pro extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
      cursor: "pointer",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
      cursor: "pointer",
    };
    const myCard = {
      backgroundColor: "#fff",
      cursor: "pointer",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
      cursor: "pointer",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Products & Services</a>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      Protections & Coverages
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Rental Car Insurance Coverage and Protection Plans</h1>
                <label className="my-3">
                  <b>Peace of Mind for Your Next Car Rental</b>
                </label>
                <p className="mb-3">
                  Looking for rental car insurance coverage? If so, you’re
                  making a smart move that will protect you in unfortunate
                  circumstances that may arise while driving your vehicle.
                  Budget has a diverse selection of rental car protection
                  choices. Whether you’re traveling for business or pleasure,
                  with family or alone, you’re sure to find the right insurance
                  plan for you. The right rental car insurance will give you
                  peace of mind, no matter what situations come up on the road.
                  Find plans to safeguard you in case of accident, injury,
                  damage, or theft. Check out our rental car insurance options
                  above.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Loss-Damage-Waiver-(LDW)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Loss Damage Waiver (LDW)
                    </label>
                    <label style={myParas}>
                      Loss Damage Waiver (LDW) is an option that relieves you of
                      financial responsibility if your rental car is damaged or
                      stolen. Also known as CDW in some countries.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Theft-Protection-(TP)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Theft Protection (TP)
                    </label>
                    <label style={myParas}>
                      If your rental vehicle is stolen, this will cover the cost
                      of replacing it (or parts of it). You will, however, have
                      to pay an excess.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Personal-Accident-and-Effects-(PAE)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Personal Accident and Effects (PAE)
                    </label>
                    <label style={myParas}>
                      PAE protects you and your passengers against accident,
                      injury, and loss to personal property.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Supplemental-Liability-Insurance-(SLI)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Supplemental Liability Insurance (SLI)
                    </label>
                    <label style={myParas}>
                      Supplemental Liability Insurance protects you and all
                      authorized drivers against third-party injury and
                      property-damage claims for rentals in the USA.
                    </label>
                    <label style={myLink}>Learn More </label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
