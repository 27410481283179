import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Mission from "../components/about/Mission";
import Header from "../common/header/Header";

const OurMission = () => {
  const breadcrumb = [
    "About Budget Rent A Car",
    "Company Information",
    "Our Mission",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Header
        title="Budget USA: Mission Statement"
        subtitle="Growing the business since 1958"
      />
      <Mission />
      <FooterOne />
    </>
  );
};

export default OurMission;
