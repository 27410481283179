import React from "react";

const HeadNotice = () => {
  return (
    <div className="container py-4 d-xl-block d-none">
      <span className="span-heading text-center">
        <h1 className="mb-3">Avis Budget Group Privacy Notice</h1>
        <p style={{ fontSize: "20px" }}>Last Updated Date: December 29, 2022</p>
        <hr />
      </span>
    </div>
  );
};

export default HeadNotice;
