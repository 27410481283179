import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import OfferDetails from "../components/military/OfferDetails";
import TermConditions from "../components/military/TermConditions";
import LandingSelectCar from "../components/road/LandingSelectCar";

const MilitaryVeterans = () => {
  const breadcrumb = [
    "Deals",
    "Partner Offers",
    "Up to 25% Off Car Rentals for Veterans",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image="military-veterans.webp"
        title="Get the Budget Military Discount - Up to 25% Off with Veterans Advantage"
        sub="To those who serve, thank you."
      />
      <OfferDetails />
      <TermConditions />
      <FooterOne />
    </>
  );
};

export default MilitaryVeterans;
