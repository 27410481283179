import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Banner from "../components/rent/Banner";
import { Link } from "react-router-dom";

const BusinessRental = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const breadcrumb = [
    "Programs",
    "Business Car Rental",
    "Budget Business Programs",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Banner
        rent="Budget Business Program"
        image="budget-business.webp"
        type="support"
        sub="Ready, set, rewards"
      />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-dark">Get more for your small business.</h4>
          </div>
          <div className="col-md-6">
            <h4 className="text-dark">
              Already a member?{" "}
              <Link className="linked text-decoration-underline">
                Log In Now
              </Link>
            </h4>
          </div>
        </div>
        <p className="mt-5 mb-3">
          The Budget Business Program is an easy-to-join program with endless
          travel perks for you and your company. As members, you save money on
          every rental, earn credits and so much more!
        </p>
        <p className="mb-3">
          Enroll today to get your Budget Customer Discount (BCD) number to
          share with your company's travelers, and start saving and earning.
        </p>
        <p className="mb-3">
          Some of our Budget Business Program benefits include:
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>Up to 30% off base rates.</li>
          <li>$3 reward credit for every qualifying rental day.</li>
          <li>Access to the Budget Business Program member-only website.</li>
          <li>
            Complimentary Fastbreak membership to allow you skip the counter at
            pickup.
          </li>
          <li>A dedicated support team.</li>
        </ul>
        <p className="mb-3">
          Enroll now to start saving and earning immediately.
        </p>
        <p className="mb-3">
          View{" "}
          <Link className="linked text-decoration-underline">
            Program Terms and Conditions.
          </Link>
        </p>
        <hr />
        <h3 className="mb-3">Name</h3>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="first_name">First Name</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="first_name" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="last_name">Last Name</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="last_name" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="job_title">
              Job Title <i>(optional)</i>
            </label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="job_title" />
          </div>
        </div>
        <hr />
        <h3 className="mb-3">Contact Information</h3>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="email">Email</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="email" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="re-email">Re-enter Email</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="re-email" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="phone">Phone</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="phone" />
          </div>
        </div>
        <p className="mb-3">
          Your Budget Corporate Discount (BCD) number will be sent to the email
          provided.
        </p>
        <hr />
        <h3 className="mb-3">Communication Preferences</h3>
        <div className="row mb-3">
          <div className="col-md-6 text-dark d-flex align-items-start">
            <input
              type="radio"
              name="communication"
              id="communication_true"
              className="mt-2 me-2"
              checked
            />
            <label for="communication_true">
              Yes, I want to receive updates from Budget about products,
              services, special offers and important updates regarding the
              Budget Business Program.
            </label>
          </div>
          <div className="col-md-6 text-dark d-flex align-items-start">
            <input
              type="radio"
              name="communication"
              id="communication_false"
              className="mt-2 me-2"
            />
            <label for="communication_false">
              No, I do not want to receive promotional e-mail offers at this
              time.
            </label>
          </div>
        </div>
        <hr />
        <h3 className="mb-3">Company Information</h3>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="business_name">Business Name</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="business_name" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="address_line_1">Address Line 1</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="address_line_1" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="address_line_2">
              Address Line 2 <i>(optional)</i>
            </label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="address_line_2" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="city">City</label>
          </div>
          <div className="col-md-9">
            <input className="form-control" type="text" id="city" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="state">State</label>
          </div>
          <div className="col-md-9">
            <select className="form-control">
              <option>Please Select One</option>
              <option>Alaska</option>
              <option>Arizona</option>
              <option>Arkansas</option>
              <option>Indiana</option>
            </select>
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="zip">Zip</label>
          </div>
          <div className="col-md-5">
            <input className="form-control" type="text" id="zip" />
          </div>
        </div>
        <div className="row text-dark mb-3">
          <div className="col-md-3">
            <label for="country">Country</label>
          </div>
          <div className="col-md-9">
            <select className="form-control">
              <option>U.S.A.</option>
              <option>England</option>
              <option>Indonesia</option>
              <option>France</option>
            </select>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Link
            className="linked text-decoration-none"
            to={publicUrl + "program"}
          >
            Cancel
          </Link>
          <button className="btnCar">Submit</button>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default BusinessRental;
