import React from "react";

const FuelPlan = () => {
  return (
    <div className="container">
      <hr />
      <h3>Fuel Plan</h3>
      <p className="mt-3 mb-3">
        Budget offers several refueling options, whether you choose to do it
        yourself or prefer to leave the hassle to us. In most locations, when
        you collect your vehicle, the Rental Sales Agent will explain the fuel
        options available to you and arrange the option which best suits your
        requirements. You will be asked to select from one of the below options:
      </p>
      <b className="mt-3">Fuel Up Front </b>
      <p className="mt-3 mb-3">
        This option lets you purchase a full tank of fuel at the beginning of
        your car rental, allowing you to return the vehicle empty. We offer the
        market-average pump price.
      </p>
      <b className="mt-3">Pay On Return</b>
      <p className="mt-3 mb-3">
        If you do not take Fuel Up Front, and later find you haven't got time to
        refuel your vehicle at the end of your rental, we will refill the tank
        when you return. Please be aware a service charge will apply on top of
        the basic cost of the fuel itself.
      </p>
      <b className="mt-3">EZ Fuel™</b>
      <p className="mt-3 mb-3">
        Going 75 miles or less? With EZ Fuel you can leave the refuelling to us.
      </p>
      <b className="mt-3">Bring Back Full</b>
      <p className="mt-3 mb-3">
        Budget will supply you with a full tank of fuel at the beginning of your
        rental. You will then be expected to return the vehicle with a full
        tank. Any missing fuel will be charged on the Pay On Return basis
        outlined above.
      </p>
      <p className="mt-3">
        Please note that fuel plans and fuel prices will vary from country to
        country.
      </p>
      <p className="mb-3">
        Plans can be purchased at the counter upon car pick-up
      </p>
    </div>
  );
};

export default FuelPlan;
