import React from "react";
import { Link } from "react-router-dom";

const OfferDetails = () => {
  return (
    <div className="container py-4">
      <h4>Offer Details</h4>
      <p className="mt-4">
        To recognize the service of U.S. military veterans, active-duty
        military, National Guard and Reservists, and their families, Budget is
        honored to provide a military discount on car rental. Save up to 25% off
        car rentals, simply by being a member of Veterans Advantage.
      </p>
      <p className="mt-3">
        Not yet a Veterans Advantage member? Good news! It’s free and easy to
        join. Simply{" "}
        <Link className="linked text-decoration-none">sign up today</Link>!
      </p>
      <h4 className="mt-3">Apply the Discount</h4>
      <p className="mt-3">
        Ready to take advantage of the discount? After filling out your pickup
        and return destinations and rental dates anywhere on Budget.com, enter
        code X612500 into the offer codes drop-down form. After you click
        “Select My Car,” you can verify that the coupon code is being applied in
        the drop-down menu under “Lower Rates/This BCD.” If you receive a notice
        that says “Your offer code is invalid,” select “Learn Why?” for more
        information. There are some blackout dates and non-participating
        locations, which may make military car rental deals unavailable.
      </p>
      <h4 className="mt-3">Details</h4>
      <p className="mt-3">
        And so you aren’t surprised when you check out, the base offer doesn’t
        apply to specialty vehicles, taxes, concession recovery fees, add-on
        products, fueling plans, vehicle license recovery fees, LDWs, or
        customer facility charges. We love that you love our coupons, but sadly,
        there is no combining of coupons or discounts.
      </p>
      <p className="mt-3 mb-5">
        Enjoy your journey with a veteran discount car rental from Budget when
        you make a reservation today.
      </p>
      <hr />
    </div>
  );
};

export default OfferDetails;
