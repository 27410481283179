import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Header from "../common/header/Header";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import International from "../components/about/International";

const RentalInternationalWebsites = () => {
  const breadcrumb = [
    "About Budget Rent A Car",
    "Company Information",
    "Budget Web Sites Worldwide",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Header
        title="Budget Car Rental International Websites"
        subtitle="There's a Budget Rent A Car Web site for everyone, everywhere!"
      />
      <International />
      <FooterOne />
    </>
  );
};

export default RentalInternationalWebsites;
