import React from "react";
import { Link } from "react-router-dom";

export default class LTR extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section style={{ backgroundColor: "rgb(247, 139, 0)" }}>
          <div className="container pb-3">
            <div className="row">
              <div className="col-sm-4 pt-3 pe-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your pick-up location or zip code"
                />
              </div>
              <div className="col-sm-3 pt-3 ps-1 pe-0">
                <input type="date" className="form-control" />
              </div>
              <div className="col-sm-3 pt-3 pe-0 ps-1">
                <input type="time" className="form-control" />
              </div>
              <div className="col-sm-2 ps-1 pt-3">
                <button
                  className="btn btn-primary form-control"
                  type="submit"
                  style={{ backgroundColor: "#00285f" }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Products & Services</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Services</a>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      Budget by the Month - Long Term Car Rental
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Save on Long-Term, Monthly Car Rental with Budget</h1>
                <label className="my-3"></label>
                <p className="mb-3">
                  Reserve a monthly car rental from a brand you can trust with
                  locations across the United States. Experience convenience and
                  cheap long-term car rental prices. Every Budget long-term car
                  rental comes with the following perks:
                </p>
                <ol className="mb-3">
                  <li>
                    <b>+ Great Rates:</b> The longer you rent, the more you
                    save. If you’re looking for cheap car leasing options you
                    may enjoy far greater savings and flexibility with{" "}
                    <a href="#" style={myLink}>
                      renting vs leasing.
                    </a>
                  </li>
                  <li>
                    <b>+ Flexible Contracts:</b> No need to be stuck in a lease;
                    call a car yours for as little as one month.{" "}
                  </li>
                  <li>
                    <b>+ One-Way Car Rental:</b> Plan an extended road trip
                    across the country and select different pickup and drop-off
                    locations.{" "}
                  </li>
                  <li>
                    <b>
                      +{" "}
                      <a href="#" style={myLink}>
                        Loss Damage Waiver (LDW)
                      </a>{" "}
                      Discount:
                    </b>{" "}
                    Save even more with a discount on a monthly rental LDW.{" "}
                  </li>
                  <li>
                    <b>+ Vehicle Exchange:</b> If you need to change your car
                    for any reason, contact the rental location from where you
                    picked up your car, to schedule a vehicle swap.{" "}
                  </li>
                </ol>
                <p className="mb-3">
                  Enjoy the perks of renting a car for a month or more when you
                  make a reservation today.{" "}
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-6">
                <img
                  className="img-fluid w-100"
                  src={publicUrl + "assets/images/resources/ltr1.webp"}
                />
              </div>
              <div className="col-sm-6">
                <h4 className="mb-3">
                  Short-Term Car Lease vs Monthly Car Rental
                </h4>
                <p className="mb-3">
                  When you need temporary transportation, you may be{" "}
                  <a href="#" style={myLink}>
                    considering if you should get a short-term car lease or a
                    monthly car rental.
                  </a>{" "}
                  Although it is possible to lease a car for a month, it is hard
                  to find a leasing company that will agree to it. Not to
                  mention, most one-month car leases require a credit check, and
                  have rigid start and end dates.
                </p>
                <p className="mb-3">
                  What sets monthly car rentals apart from leases is that you
                  can rent by the month without having any long-term
                  commitments. Rentals are typically less expensive and don’t
                  require a credit check. Simply put, renting a car gives you
                  more freedom, flexibility, and savings.{" "}
                </p>
                <table className="tabel table-bordered w-100">
                  <thead>
                    <tr>
                      <th>Monthly Car Rental</th>
                      <th>One-Month Car Lease</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Easy to find- visit any US Budget location!</td>
                      <td>Difficult to find</td>
                    </tr>
                    <tr>
                      <td>Credit check not required</td>
                      <td>Requires a credit check</td>
                    </tr>
                    <tr>
                      <td>No additional ownership fees</td>
                      <td>Includes fees for ownership and leasing</td>
                    </tr>
                    <tr>
                      <td>Enjoy flexibility, rent from month to month</td>
                      <td>Rigid start and end dates</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-5">
            <div className="row mb-4">
              <div className="col-sm-12">
                <h4 className="mb-3">Long-Term Car Rental Questions</h4>
                <p className="mb-3">
                  We get a lot of questions about long-term car rentals. Here
                  are your answers:
                </p>
                <ol className="mb-3">
                  <li>
                    <b>+ Can you rent a car by the month?</b>
                  </li>
                  <li>
                    <ol className="mb-3">
                      <li>
                        + Yes, you can get a car rental for a month! Keep in
                        mind that renting once for a longer duration and
                        renewing your rental on a monthly basis will change
                        pricing.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>+ Can you rent a car for 3 months?</b>
                  </li>
                  <li>
                    <ol className="mb-3">
                      <li>
                        + Yes, you definitely can get a car rental for 3 months.
                        The longer you rent, the more you save!
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>+ Can you lease a car for 6 months?</b>
                  </li>
                  <li>
                    <ol className="mb-3">
                      <li>
                        + Renting vs leasing offers many benefits (see above).
                        Long-term car rental for 6 months or more is offered by
                        Budget. Just let us know the time period and learn how
                        much you can benefit.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>+ Can you rent a car for a year?</b>
                  </li>
                  <li>
                    <ol className="mb-3">
                      <li>
                        + While we don’t offer car rental over a year long, you
                        can always rent a car for up to 11 months at a time! You
                        will be able to add up savings this way.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>+ How much does a rental car cost for a month?</b>
                  </li>
                  <li>
                    <ol className="mb-3">
                      <li>
                        + That depends – the longer your car rental, the more
                        you save. Why not give us a call (1-866-371-4976) and
                        get a rate and decide if you want to book. Or Book
                        online and increase your savings on your car rental when
                        you pay online?
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>+ Where can I rent a car for a month cheap?</b>
                  </li>
                  <li>
                    <ol className="mb-3">
                      <li>
                        + Most Budget locations offer long-term car rental. To
                        explore popular destinations near you, you can check out
                        the localization section. Otherwise, you can search your
                        area for car rental locations and find out from your
                        local shop.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container p-5" style={{ backgroundColor: "#eaeaea" }}>
            <div className="row">
              <div className="col-sm-12">
                <h4>Deals for Cheap Long-Term Car Rental</h4>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="row">
                  <div className="col-sm-6">
                    <img
                      className="img-fluid w-100"
                      src={publicUrl + "assets/images/resources/ltr2.webp"}
                    />
                  </div>
                  <div className="col-sm-6">
                    <a href="#">
                      <h5 style={myLink}>
                        Enjoy Big Savings When You Pay Today
                      </h5>
                    </a>
                    <p className="mt-3">
                      Rent directly on Budget.com and pay now to save up to 35%
                      off. Click “Pay Now” when you book to take advantage of
                      this exclusive deal.
                    </p>
                    <button
                      className="btn mt-3"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      Save on Your Rental
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="row">
                  <div className="col-sm-6">
                    <img
                      className="img-fluid w-100"
                      src={publicUrl + "assets/images/resources/ltr3.avif"}
                      alt="Budget Car Rental"
                    />
                  </div>
                  <div className="col-sm-6">
                    <a href="#">
                      <h5 style={myLink}>Budget Car Rental Coupons</h5>
                    </a>
                    <p className="mt-3">
                      Check out this featured list of Budget coupon codes, and
                      find the best deal for your next car rental. Get
                      discounts, upgrades, and more!
                    </p>
                    <button
                      className="btn mt-3"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      Browse Coupon Codes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-3">
            <div className="row pt-4">
              <div className="col-sm-12">
                <h4>Travel Inspiration for a Long Trip</h4>
              </div>
            </div>
            <div className="row mt-4">
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={publicUrl + "assets/images/resources/ltr5.webp"}
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={publicUrl + "assets/images/resources/ltr5.webp"}
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="blog-one__single">
                  <div className="blog-one__single-img">
                    <img
                      src={publicUrl + "assets/images/resources/ltr5.webp"}
                      alt=""
                    />
                  </div>

                  <div className="blog-one__single-content">
                    <a href="#">
                      <h5>Excepteur occaecat cupidatat </h5>
                    </a>
                    <p className="my-5" style={myPara}>
                      Take your long-term car rental down California State Route
                      1 and experience scenic beaches, unusual landmarks, and
                      delicious cuisine.{" "}
                    </p>
                    <button
                      className="btn mt-3 w-100"
                      style={{
                        backgroundColor: "rgb(0, 40, 95)",
                        color: "#fff",
                        padding: "0px 50px",
                        height: "50px",
                      }}
                    >
                      View Trip Itinerary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
