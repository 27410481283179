import React from "react";

const IATA = () => {
  return (
    <div
      style={{
        background: `url(${process.env.PUBLIC_URL}/assets/cars-budget/travel-agent.jpg)`,
      }}
    >
      <div className="container py-4">
        <h1 className="text-center text-dark" style={{ marginTop: "1em" }}>
          Travel Agent IATA Code Reservations
        </h1>
        <div
          className="bg-white p-5"
          style={{ marginTop: "1em", marginBottom: "3em" }}
        >
          <p className="text-dark mb-4">
            Budget sincerly appreciates your contributions, as well as the
            efforts of the thousands of other travel agents who help grow our
            business every day. It's our pleasure to offer you loyalty programs
            to reward your efforts, and GDS reference cards to help make your
            job easier.
          </p>
          <form>
            <div className="row">
              <div className="col-md-6 mb-5">
                <label for="iata" class="form-label text-dark">
                  IATA/ARC
                </label>
                <input type="text" class="form-control" id="iata" />
              </div>
              <div className="col-md-6 mb-5">
                <label for="ur-mem" class="form-label text-dark">
                  UR MEM #
                </label>
                <input type="text" class="form-control text-dark" id="ur-mem" />
              </div>
              <div className="col-md-6 mb-5 text-end me-2">
                <button className="btnCar iata">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IATA;
