import React from "react";

const OfferDetails = () => {
    return (
        <div className="container">
            <hr />
            <h3>Share The Drive...</h3>
            <p className="mt-3 mb-3">
                Add one or more additional drivers to your rental agreement so you can take a moment to relax while someone else is covered to get behind the wheel. By sharing the driving responsibilities, you can lower the risk of having an accident. Please be aware that if an unnamed driver has an accident, your cover will be invalidated.
            </p>
            <p className="mt-3 mb-3">
                Additional drivers are permitted to drive provided they meet the following requirements:
            </p>
            <ol className="mb-3">
                <li>•	At the time of picking up the vehicle, all additional drivers must be present with their driver licence.</li>
                <li>•	If the additional driver is below the age of 25 (different age apply to rentals in different countries), a surcharge may be charged.</li>
            </ol>
            <p className="mt-3 mb-3">
                Additional drivers must meet the same terms and conditions as the main driver to be covered under the same rental agreement.
            </p>
            <p className="mt-3 mb-3">
                You can add additional drivers to your car rental when you collect your vehicle. The additional driver fee will be charged for each additional person added.
            </p>
        </div>
    );
};

export default OfferDetails;
