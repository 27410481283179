import React, { useEffect, useState, Component } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { formatRupiah, getToken } from "../../core/functions";
import { API_URL } from "../../core/constant";
import HeaderAlt from "../../common/header/HeaderAlt";
import FooterOne from "../../common/footer/FooterOne";
import { useLocation } from "react-router-dom";
import { generateRandomNumbers } from "../../core/functions";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { format } from "date-fns";
class BDN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate(va) {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(va);
    return date.toLocaleDateString(undefined, options);
  }

  cancelRes(book) {
    Axios.post(`${API_URL}/api/booking/cancel`, {
      book: book,
      emailForm: localStorage.getItem("email"),
    }).then((res) => {
      console.log(res.data.status);
      if (res.data.status == false) {
        alert(res.data.msg);
      } else {
        alert("Booking berhasil dibatalkan");
        this.props.navigate("/makereservation", {
          state: {
            booking: book,
          },
        });
      }
    });
  }

  render() {
    console.log(this.props.before);
    let requestTime = this.props.before.requestTime;
    let pickup_date = this.props.before.date;
    let pickup_time = this.props.before.time;
    let dropoff_date = this.props.before.return_date;
    let dropoff_time = this.props.before.return_time;
    let location = this.props.before.location;
    let paylaterPrice = this.props.before.paylaterPrice;

    let btn;
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myLink = {
      color: "rgb(212, 0, 42)",
    };
    const myBorder = {
      borderBottom: "2px solid rgb(212, 0, 42)",
    };
    const myLinkDis = {
      color: "rgb(168, 168, 168)",
    };
    const myBorderDis = {
      borderBottom: "2px solid rgb(168, 168, 168)",
    };
    const selectLink = {
      border: "2px solid rgb(212, 0, 42)",
      color: "#140a55",
    };
    const btnLink = {
      backgroundColor: "rgb(212, 0, 42)",
      color: "rgb(255, 255, 255)",
      padding: "0px 50px",
      // height: "50px"
    };
    // if (this.props.header.status_book == 'Hold') {
    // btn = <div><button className='btn py-1' style={btnLink}>Modify Reservation</button><br/><button className='btn py-1 mt-2' onClick={(e)=>this.cancelRes(this.props.header.rent_id)} style={btnLink}>Cancel Reservation</button></div>
    btn = (
      <div>
        {this.props.before.paymentType == "paylaters" ? (
          ""
        ) : (
          <>
            <a
              className="btn py-1"
              href={`${this.props.midlink}`}
              target="_blank"
              style={btnLink}
            >
              Pay Now
            </a>
            <br />
          </>
        )}
        <button
          style={btnLink}
          className="btn py-1 mt-2"
          onClick={(e) =>
            this.cancelRes(
              this.props.header.rent_id,
              this.props.before.bookingNumber
            )
          }
        >
          Cancel Reservation
        </button>
      </div>
    );
    // } else {
    //     btn = <h1></h1>
    // }
    let pickupDate = new Date(localStorage.getItem("pickup_date"));
    let dropoffDate = new Date(localStorage.getItem("dropoff_date"));

    let pickupDateYear = pickupDate.getFullYear();
    let pickupDatemonth = (pickupDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    let pickupDateday = pickupDate.getDate().toString().padStart(2, "0");

    let dropoffDateYear = dropoffDate.getFullYear();
    let dropoffDatemonth = (dropoffDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    let dropoffDateday = dropoffDate.getDate().toString().padStart(2, "0");

    pickupDate = pickupDateYear + "-" + pickupDatemonth + "-" + pickupDateday;
    dropoffDate =
      dropoffDateYear + "-" + dropoffDatemonth + "-" + dropoffDateday;

    const carData = {
      image: this.props.before.vehicle.category.image_url,
      name: this.props.before.vehicle.category.name,
      make: this.props.before.vehicle.category.make,
      model: this.props.before.vehicle.category.model,
      seats: this.props.before.vehicle.capacity.seats,
      capacity:
        this.props.before.vehicle.capacity.luggage_capacity.large_suitcase,
      transmission: this.props.before.vehicle.category.vehicle_transmission,
    };

    const carDataString = JSON.stringify(carData);

    localStorage.setItem("carData", carDataString);

    localStorage.setItem("total", this.props.before.total_pay);

    return (
      <>
        {this.props.before.paymentType != "paylaters" ? (
          <>
            <section>
              <div className="container">
                <div className="row mt-3">
                  <div className="col-sm-6">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Rent ID</td>
                          <td>
                            <b>{this.props.header.rent_id}</b>
                          </td>
                        </tr>
                        {this.props.before.paymentType == "paylaters" ? (
                          <tr>
                            <td>Booking Code</td>
                            <td>
                              <b>{this.props.before.bookingNumber}</b>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>Reservation Status</td>
                          <td>
                            <b>{this.props.header.status_book}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Booking at</td>
                          <td>
                            <b>
                              {this.formatDate(this.props.header.created_at)}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col-sm-6 text-end">{btn}</div>
                </div>
              </div>
            </section>
            <section className="mb-4">
              <div className="container">
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6 border-bottom">
                        <div className="row">
                          <div className="col-sm-6">
                            <p style={myLink}>Pick-Up</p>
                            <p>
                              <b>
                                {localStorage.getItem("nameLocation") ?? ""}
                              </b>
                              <br />
                              {localStorage.getItem("cityLocation") ?? ""},{" "}
                              {localStorage.getItem("codeLocation") ?? ""}
                              <br />
                              {localStorage.getItem("addressLine1Location") ??
                                ""}
                              ,{" "}
                              {localStorage.getItem("addressLine2Location") ??
                                ""}
                              ,{" "}
                              {localStorage.getItem("postalCodeLocation") ?? ""}
                              , {localStorage.getItem("countryCodePick") ?? ""}
                              <br />
                              <b>
                                {localStorage.getItem("telephoneLocation") ??
                                  ""}
                              </b>
                              <br />
                              <b>Location Code: </b>{" "}
                              {localStorage.getItem("codeLocation") ?? ""}
                            </p>
                            <p>
                              {this.props.before.time}{" "}
                              {this.formatDate(this.props.before.date)}
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <p style={myLink}>Return</p>
                            <p>
                              <b>
                                {localStorage.getItem("nameLocation") ?? ""}
                              </b>
                              <br />
                              {localStorage.getItem("cityLocation") ?? ""},{" "}
                              {localStorage.getItem("codeLocation") ?? ""}
                              <br />
                              {localStorage.getItem("addressLine1Location") ??
                                ""}
                              ,{" "}
                              {localStorage.getItem("addressLine2Location") ??
                                ""}
                              ,{" "}
                              {localStorage.getItem("postalCodeLocation") ?? ""}
                              , {localStorage.getItem("countryCodePick") ?? ""}
                              <br />
                              <b>
                                {localStorage.getItem("telephoneLocation") ??
                                  ""}
                              </b>
                              <br />
                              <b>Location Code: </b>{" "}
                              {localStorage.getItem("codeLocation") ?? ""}
                            </p>
                            <p>
                              {this.props.before.reutrn_time}{" "}
                              {this.formatDate(this.props.before.return_date)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 border-start border-bottom">
                        <div className="row">
                          <div className="col-sm-6">
                            <p>Base Rate</p>
                            <p style={myLink}>Taxes & Fees</p>
                            <p>Estimated Total</p>
                            <p style={myLink}>See Rate Terms</p>
                          </div>
                          <div className="col-sm-6 text-end">
                            <p>{formatRupiah(this.props.before.price)}</p>
                            <p>{formatRupiah(this.props.before.tax)}</p>
                            <p>
                              <b>{formatRupiah(this.props.before.total_pay)}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="row mt-2">
                          <div className="col-sm-3">
                            <img
                              src={this.props.before.vehicle.category.image_url}
                              className="img-fluid"
                            />
                          </div>
                          <div className="col-sm-9">
                            <p>
                              <h5 className="text-dark">
                                {this.props.before.vehicle.category.name}
                              </h5>
                            </p>
                            <p>
                              {this.props.before.vehicle.category.make}{" "}
                              {this.props.before.vehicle.category.model}
                            </p>
                            <p>
                              <img src="/assets/images/icon/user.png" />{" "}
                              {this.props.before.vehicle.capacity.seats}
                              <img
                                src="/assets/images/icon/suitcase.png"
                                className="ms-2"
                              />{" "}
                              {
                                this.props.before.vehicle.capacity
                                  .luggage_capacity.large_suitcase
                              }
                              L
                              <img
                                src="/assets/images/icon/suitcase.png"
                                className="ms-2"
                              />{" "}
                              {
                                this.props.before.vehicle.capacity
                                  .luggage_capacity.large_suitcase
                              }
                              S
                            </p>
                            <p>
                              {
                                this.props.before.vehicle.category
                                  .vehicle_transmission
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 border-start text-end text-success">
                        <p>Other Information</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <div className="container my-5">
              <section id="thank-you-note" className="mt-4">
                <div className="d-flex flex-column w-100 align-items-center text-center grid gap-3">
                  <CiCircleCheck
                    style={{ fontSize: "100px", color: "#4fdb6d" }}
                  />
                  <div
                    style={{ color: "#4fdb6d", fontWeight: "bolder" }}
                    className="fs-2"
                  >
                    THANK YOU.
                  </div>
                  <div className="fs-5">
                    Your booking is complete. We'll send you a confirmation
                    email shortly.
                  </div>
                  <div className="fs-5">
                    Your reference: {localStorage.getItem("bookingNumber")}
                  </div>
                  <div style={{ fontSize: "18px" }}>
                    Please read our{" "}
                    <Link
                      to={process.env.PUBLIC_URL + `/`}
                      style={{
                        textDecoration: "underline",
                        color: "red",
                      }}
                    >
                      terms and condition
                    </Link>
                  </div>
                </div>
              </section>
              {/* Car Information */}
              <section id="car-information" className="mt-4">
                <div
                  style={{ backgroundColor: "#ebe8e8" }}
                  className="d-flex flex-column grid"
                >
                  <div className="d-flex justify-content-between">
                    <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
                      Your Booking
                    </div>
                    <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
                      Booking Date:{" "}
                      {format(new Date(requestTime), "dd MMMM yyyy, HH:mm")}
                    </div>
                  </div>

                  <div
                    style={{
                      borderTop: "1px solid #d1d1d1",
                      borderBottom: "1px solid #d1d1d1",
                    }}
                  >
                    <div className="px-4">
                      <div className="row">
                        <div
                          className="col-sm py-4"
                          style={{ borderRight: "1px solid #d1d1d1" }}
                        >
                          <div className="text-danger">When & Where</div>
                          <p>
                            {format(new Date(pickup_date), "dd MMM yyy")}{" "}
                            {pickup_time} -{" "}
                            {format(new Date(dropoff_date), "dd MMM yyy")}{" "}
                            {dropoff_time}
                          </p>
                          <p>{location}</p>
                        </div>
                        <div
                          className="col-sm py-4"
                          style={{ borderRight: "1px solid #d1d1d1" }}
                        >
                          <div className="row mt-2">
                            <div className="col-sm-12">
                              <p>
                                <h5 className="text-dark">
                                  {carData.make ?? ""}
                                </h5>
                              </p>
                              <p>
                                {carData.make ?? ""} {carData.model ?? ""}
                              </p>
                              <p>{carData.transmission ?? ""}</p>
                            </div>
                          </div>
                          <div className="col-sm-12 d-flex justify-content-center">
                            <img
                              src={carData.image ?? ""}
                              className="img-fluid"
                              width={200}
                            />
                          </div>
                        </div>
                        <div className="col-sm py-4">
                          <div className="text-danger">Extras</div>
                          <p>None Selected</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end p-4">
                    <div style={{ marginRight: "250px" }}>Total</div>
                    <div>{paylaterPrice}</div>
                  </div>
                </div>
              </section>
              {/* Information */}
              <section id="information" className="mt-4">
                <div className="px-4">
                  <div className="row">
                    <div className="col-sm d-flex flex-column grid gap-3">
                      <div className="fs-5" style={{ fontWeight: "bold" }}>
                        When you collect your car
                      </div>
                      <div>
                        One of your peyser cords must be in the name of the main
                        driver unless booked on a centrally billed company
                        credit card or account and you'll need to present the
                        following at the counter:
                      </div>
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>A valid driving license</div>
                      </div>
                      <div className="d-flex grid gap-3 align-items-center">
                        <FaCheck color="red" />
                        <div>1 payment card</div>
                      </div>
                    </div>
                    <div className="col-sm d-flex flex-column grid gap-3">
                      <div className="fs-5">Your reservation includes:</div>
                      <div className="fs-5">
                        (Inclusions are subject to your agreed/contracted rates)
                      </div>
                      <div className="d-flex">
                        <div
                          className="d-flex flex-column grid gap-3"
                          style={{ marginRight: "150px" }}
                        >
                          <div className="d-flex grid gap-3 align-items-center">
                            <FaCheck color="red" />
                            <div>Airport subcharge</div>
                          </div>
                          <div className="d-flex grid gap-3 align-items-center">
                            <FaCheck color="red" />
                            <div>Local tax</div>
                          </div>
                          <div className="d-flex grid gap-3 align-items-center">
                            <FaCheck color="red" />
                            <div>Vehicle Luggage Free</div>
                          </div>
                        </div>
                        <div className="d-flex flex-column grid gap-3">
                          <div className="d-flex grid gap-3 align-items-center">
                            <FaCheck color="red" />
                            <div>Theft cover</div>
                          </div>
                          <div className="d-flex grid gap-3 align-items-center">
                            <FaCheck color="red" />
                            <div>Damage cover</div>
                          </div>
                          <div className="d-flex grid gap-3 align-items-center">
                            <FaCheck color="red" />
                            <div>Unlimited</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Rental Information */}
              <section id="rental-information" className="my-4">
                <div style={{ backgroundColor: "#ebe8e8" }} className="p-4">
                  <div className="fs-5" style={{ fontWeight: "bold" }}>
                    Getting to your rental station
                  </div>
                  <div class="mt-4">
                    <div class="row">
                      <div class="col-sm grid gap-2 d-flex flex-column">
                        <div style={{ color: "rgb(212, 0, 42)" }}>Pick Up</div>
                        <p>
                          <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                          <br />
                          {localStorage.getItem("cityLocation") ?? ""},{" "}
                          {localStorage.getItem("codeLocation") ?? ""}
                          <br />
                          {localStorage.getItem("addressLine1Location") ??
                            ""},{" "}
                          {localStorage.getItem("addressLine2Location") ?? ""},{" "}
                          {localStorage.getItem("postalCodeLocation") ?? ""},{" "}
                          {localStorage.getItem("countryCodePick") ?? ""}
                          <br />
                          <b>Location Code: </b>{" "}
                          {localStorage.getItem("codeLocation") ?? ""}
                        </p>
                        <div className="d-flex grid gap-3 align-items-center">
                          <FaPhoneAlt color="red" />
                          <div>
                            tel:{" "}
                            {localStorage.getItem("telephoneLocation") ?? ""}
                          </div>
                        </div>
                        {this.props.pickupLat && this.props.pickupLng && (
                          <Link
                            onClick={this.props.getPickUpDirectionUrl}
                            style={{
                              textDecoration: "underline",
                              color: "red",
                            }}
                          >
                            Get directions
                          </Link>
                        )}

                        {!this.props.pickupLat && !this.props.pickupLng && (
                          <div className="mt-3 text-danger">
                            Sorry we can't find dropoff route navigation
                          </div>
                        )}

                        {this.props.pickupLat && this.props.pickupLng && (
                          <div className="mt-3">
                            <MapContainer
                              center={[
                                this.props.pickupLat,
                                this.props.pickupLng,
                              ]}
                              zoom={13}
                              scrollWheelZoom={false}
                              style={{ height: "350px" }}
                              className="w-100"
                            >
                              <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              <Marker
                                icon={this.props.customIcon}
                                position={[
                                  this.props.pickupLat,
                                  this.props.pickupLng,
                                ]}
                              >
                                <Popup>
                                  <p>
                                    <b>
                                      {localStorage.getItem("nameLocation") ??
                                        ""}
                                    </b>
                                    <br />
                                    {localStorage.getItem("cityLocation") ?? ""}
                                    ,{" "}
                                    {localStorage.getItem("codeLocation") ?? ""}
                                    <br />
                                    {localStorage.getItem(
                                      "addressLine1Location"
                                    ) ?? ""}
                                    ,{" "}
                                    {localStorage.getItem(
                                      "addressLine2Location"
                                    ) ?? ""}
                                    ,{" "}
                                    {localStorage.getItem(
                                      "postalCodeLocation"
                                    ) ?? ""}
                                    ,{" "}
                                    {localStorage.getItem("countryCodePick") ??
                                      ""}
                                    <br />
                                    <b>
                                      {localStorage.getItem(
                                        "telephoneLocation"
                                      ) ?? ""}
                                    </b>
                                    <br />
                                    <b>Location Code: </b>{" "}
                                    {localStorage.getItem("codeLocation") ?? ""}
                                  </p>
                                </Popup>
                              </Marker>
                            </MapContainer>
                          </div>
                        )}
                      </div>
                      <div class="col-sm grid gap-2 d-flex flex-column">
                        <div style={{ color: "rgb(212, 0, 42)" }}>Drop Off</div>
                        <p>
                          <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                          <br />
                          {localStorage.getItem("cityLocation") ?? ""},{" "}
                          {localStorage.getItem("codeLocation") ?? ""}
                          <br />
                          {localStorage.getItem("addressLine1Location") ??
                            ""},{" "}
                          {localStorage.getItem("addressLine2Location") ?? ""},{" "}
                          {localStorage.getItem("postalCodeLocation") ?? ""},{" "}
                          {localStorage.getItem("countryCodePick") ?? ""}
                          <br />
                          <b>Location Code: </b>{" "}
                          {localStorage.getItem("codeLocation") ?? ""}
                        </p>
                        <div className="d-flex grid gap-3 align-items-center">
                          <FaPhoneAlt color="red" />
                          <div>
                            tel:{" "}
                            {localStorage.getItem("telephoneLocation") ?? ""}
                          </div>
                        </div>
                        {this.props.dropoffLat && this.props.dropoffLng && (
                          <Link
                            onClick={this.props.getDropOffDirectionUrl}
                            style={{
                              textDecoration: "underline",
                              color: "red",
                            }}
                          >
                            Get directions
                          </Link>
                        )}

                        {!this.props.dropoffLat && !this.props.dropoffLng && (
                          <div className="mt-3 text-danger">
                            Sorry we can't find dropoff route navigation
                          </div>
                        )}

                        {this.props.dropoffLat && this.props.dropoffLng && (
                          <div className="mt-3">
                            <MapContainer
                              center={[
                                this.props.dropoffLat,
                                this.props.dropoffLng,
                              ]}
                              zoom={13}
                              scrollWheelZoom={false}
                              style={{ height: "350px" }}
                              className="w-100"
                            >
                              <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              <Marker
                                icon={this.props.customIcon}
                                position={[
                                  this.props.dropoffLat,
                                  this.props.dropoffLng,
                                ]}
                              >
                                <Popup>
                                  <p>
                                    <b>
                                      {localStorage.getItem("nameLocation") ??
                                        ""}
                                    </b>
                                    <br />
                                    {localStorage.getItem("cityLocation") ?? ""}
                                    ,{" "}
                                    {localStorage.getItem("codeLocation") ?? ""}
                                    <br />
                                    {localStorage.getItem(
                                      "addressLine1Location"
                                    ) ?? ""}
                                    ,{" "}
                                    {localStorage.getItem(
                                      "addressLine2Location"
                                    ) ?? ""}
                                    ,{" "}
                                    {localStorage.getItem(
                                      "postalCodeLocation"
                                    ) ?? ""}
                                    ,{" "}
                                    {localStorage.getItem("countryCodePick") ??
                                      ""}
                                    <br />
                                    <b>
                                      {localStorage.getItem(
                                        "telephoneLocation"
                                      ) ?? ""}
                                    </b>
                                    <br />
                                    <b>Location Code: </b>{" "}
                                    {localStorage.getItem("codeLocation") ?? ""}
                                  </p>
                                </Popup>
                              </Marker>
                            </MapContainer>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </>
    );
  }
}

function BD(props) {
  let navigate = useNavigate();
  const [service, setService] = useState([]);
  const [header, setHeader] = useState([]);
  const [detail, setDetail] = useState([]);
  const [car, setCar] = useState([]);
  const [spec, setSpec] = useState([]);
  const [price, setPrice] = useState("");
  const [tax, setTax] = useState("");
  const [fee, setFee] = useState("");
  const [total, setTotal] = useState("");
  const [midlink, setMidlink] = useState("");

  const [pickupLat, setPickupLat] = useState("");
  const [pickupLng, setPickupLng] = useState("");
  const [dropoffLat, setDropoffLat] = useState("");
  const [dropoffLng, setDropoffLng] = useState("");

  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [errorGetCurrentLocation, setErrorGetCurrentLocation] = useState("");

  const successGetCurrentLocation = (position) => {
    setCurrentLatitude(position.coords.latitude);
    setCurrentLongitude(position.coords.longitude);
    setErrorGetCurrentLocation(null);
  };

  const errorMessageGetCurrentLocation = (error) => {
    setErrorGetCurrentLocation(error.message);
  };

  const getPickUpDirectionUrl = () => {
    console.log(currentLatitude, currentLongitude, pickupLat, pickupLng);
    if (currentLatitude && currentLongitude && pickupLat && pickupLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${pickupLat},${pickupLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  const getDropOffDirectionUrl = () => {
    if (currentLatitude && currentLongitude && dropoffLat && dropoffLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${dropoffLat},${dropoffLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  const customIcon = new Icon({
    iconUrl: publicUrl + "assets/images/icon/marker.png",
    iconSize: [38, 38],
  });

  // Run Function when pages loaded
  useEffect(() => {
    Axios.post(
      `${API_URL}/api/booking/search`,
      // `${API_URL}/api/booking/search`,
      {
        book: props.before.booking,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then((res) => {
      console.log(res);
      // setService(res.data.booking.addi);
      setHeader(res.data.booking.header);
      setDetail(res.data.booking.detail);
      // setCar(res.data.booking.car);
      // setSpec(res.data.booking.spec);
      setMidlink(res.data.booking.mid);
      //    setPrice(res.data.price);
      //    setTax(res.data.pajak);
      //    setFee(res.data.fee);
      //    setTotal(res.data.total);
      console.log(res.data.booking);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const pickupLocation = localStorage.getItem("pickup_location");
      const clientId = "04e19f27";

      if (pickupLocation) {
        try {
          const response = await Axios.get(
            `https://stage.abgapiservices.com/cars/locations/v1?keyword=${pickupLocation}&client_id=${clientId}`,
            {
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: clientId,
              },
            }
          );

          const pickupData = response.data.locations.find(
            (item) =>
              item.brand === "Avis" &&
              item.code === localStorage.getItem("pickup_location")
          );

          const dropoffData = response.data.locations.find(
            (item) =>
              item.brand === "Avis" &&
              item.code === localStorage.getItem("dropoff_location")
          );

          setPickupLat(pickupData.address.lat);
          setPickupLng(pickupData.address.long);
          setDropoffLat(dropoffData.address.lat);
          setDropoffLng(dropoffData.address.long);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetCurrentLocation,
        errorMessageGetCurrentLocation
      );
    } else {
      setErrorGetCurrentLocation(
        "Geolocation is not supported in this browser"
      );
    }
  });

  return (
    <BDN
      {...props}
      navigate={navigate}
      header={header}
      detail={detail}
      car={car}
      spec={spec}
      service={service}
      midlink={midlink}
      getPickUpDirectionUrl={getPickUpDirectionUrl}
      getDropOffDirectionUrl={getDropOffDirectionUrl}
      pickupLat={pickupLat}
      pickupLng={pickupLng}
      dropoffLat={dropoffLat}
      dropoffLng={dropoffLng}
      customIcon={customIcon}
    />
  );
}

export default BD;
