import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";
import RoadsideAssitancePlus from "../components/road/RoadsideAsistancePlus";
import Title from "../Title";

const RoadsideAssitance = () => {
  const breadcrumb = ["Deals", "US Offers", "Extended Roadside Assistance"];
  Title("Roadside Asistance");
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image=""
        title="Budget Rental Car Extended Roadside Assistance"
        sub="Enjoy a Safer Stress-Free Driving Experience!"
      />
      <RoadsideAssitancePlus />
      <FooterOne />
    </>
  );
};

export default RoadsideAssitance;
