import React from "react";
import Axios from "axios";
import countries from "../../Countries.json";
import worldLocation from "../../budget_locations2.json";
import { API_URL } from "../../core/constant.js";
import { times } from "../../core/time.js";
import DatePicker from "react-datepicker";
export default class HeroOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      countryPick: "",
      date: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      time: "08:00 AM",
      return_location: "",
      return_date: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
      return_time: "08:00 AM",
      age: "26",
      country: "ID",
      user_id: "",
      offer_code: "",
      vehicle: "",
      locationName: "",
      searchData: [], // Add your data here
      searchResults: [],
      returnlocationName: "",
      returnsearchData: [], // Add your data here
      returnsearchResults: [],
      isShowOfferCode: false,
      isShowWizardCode: false,
      awd_number: "",
      coupon_code: "",
      wizard_number: "",
      last_name: "",
      excludedFields: ["NUM", "Sitecode"],
      selectedLocation: null,
    };
  }

  submit(event) {
    event.preventDefault();

    Axios.post(
      `${API_URL}/api/booking/check`,
      // `http://localhost/lare/public/api/booking/check`,
      {
        location: this.state.location,
        date: this.state.date,
        time: this.state.time,
        return_location: this.state.return_location,
        return_date: this.state.return_date,
        return_time: this.state.return_time,
        age: this.state.age,
        country: this.state.country,
        user_id: this.state.user_id,
        offer_code: this.state.offer_code,
        vehicle: this.state.vehicle,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        Axios.get(`https://stage.abgapiservices.com/oauth/token/v1`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            client_id: "04e19f27",
            client_secret: "4ed319e489d262258325686bf0902603",
          },
        }).then((res) => {
          //  console.log(res.data.access_token);
          localStorage.setItem("bebe", res.data.access_token);
        });

        var props = this.props;
        var states = this.state;

        if (this.state.awd_number != "") {
          var iso1 = new Date(this.state.date).toISOString();
          var iso2 = new Date(this.state.return_date).toISOString();
          Axios.get(
            "https://stage.abgapiservices.com/cars/catalog/v2/vehicles?brand=Budget&pickup_date=" +
              iso1.split(".", 1)[0] +
              "&pickup_location=" +
              this.state.location +
              "&membership_code=" +
              this.state.wizard_number +
              "&dropoff_date=" +
              iso2.split(".", 1)[0] +
              "&dropoff_location=" +
              this.state.location +
              "&country_code=ID" +
              "&discount_code=" +
              this.state.awd_number +
              "&coupon_code=" +
              this.state.coupon_code +
              "&transaction_id&member_code",
            {
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: "04e19f27",
              },
            }
          )
            .then(function (response2) {
              // handle success
              // console.log(response2.data);
              return response2.data;
            })
            .then(function (data2) {
              // handle success
              console.log("AWD LENGTH : " + data2.vehicles.length);

              if (data2.vehicles.length > 0) {
                console.log("REDIRECT");
                props.navigate("/choosecars", {
                  state: {
                    location: states.location,
                    date: states.date,
                    time: states.time,
                    return_location:
                      states.return_location == ""
                        ? states.location
                        : states.return_location,
                    return_date: states.return_date,
                    return_time: states.return_time,
                    age: states.age,
                    country: states.country,
                    user_id: states.user_id,
                    offer_code: states.offer_code,
                    vehicle: states.vehicle,
                    awd_number: states.awd_number,
                    coupon_code: states.coupon_code,
                    wizard_number: states.wizard_number,
                    last_name: states.last_name,
                    countryPick: states.countryPick,
                  },
                });
              }
            })
            .catch(function (error) {
              // handle error
              if (
                (error.response && error.response.status == 500) ||
                error.response.status == 400
              ) {
                alert(
                  "AWD Code is not found. Please use the right AWD Code or leave it blank"
                );
              }
            });
        } else {
          this.props.navigate("/choosecars", {
            state: {
              location: this.state.location,
              date: this.state.date,
              time: this.state.time,
              return_location:
                this.state.return_location == ""
                  ? this.state.location
                  : this.state.return_location,
              return_date: this.state.return_date,
              return_time: this.state.return_time,
              age: this.state.age,
              country: this.state.country,
              user_id: this.state.user_id,
              offer_code: this.state.offer_code,
              vehicle: this.state.vehicle,
              awd_number: this.state.awd_number,
              coupon_code: this.state.coupon_code,
              wizard_number: this.state.wizard_number,
              last_name: this.state.last_name,
              countryPick: this.state.countryPick,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response && error.response.status === 401) {
          alert("Please Sign In First");
        } else {
          alert(error.response && error.response.data.msg);
        }
      });
  }

  groupResultsByGroup() {
    const groupedResults = {};
    this.state.searchResults.forEach((result) => {
      if (!groupedResults[result.group]) {
        groupedResults[result.group] = [];
      }
      groupedResults[result.group].push(result);
    });
    return Object.values(groupedResults);
  }

  groupResultsByGroupReturn() {
    const groupedResults = {};
    this.state.returnsearchResults.forEach((result) => {
      if (!groupedResults[result.group]) {
        groupedResults[result.group] = [];
      }
      groupedResults[result.group].push(result);
    });
    return Object.values(groupedResults);
  }

  handleBackspaceOrDelete = (searchTerm) => {
    // Handle backspace or delete key
    if (searchTerm.trim() === "") {
      // Clear the search results and selected location when the input is empty
      this.setState({ searchResults: [], selectedLocation: null });
    }
  };

  location_change = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    this.setState({
      location: searchTerm,
    });
    console.log(event.key);

    if (event.key === "Backspace" || event.key === "Delete") {
      // Handle backspace or delete separately
      this.handleBackspaceOrDelete(searchTerm);
      return;
    }

    this.setState({ location: searchTerm });

    if (searchTerm.trim() === "") {
      // Clear the search results and selected location when the input is empty
      this.setState({ searchResults: [], selectedLocation: null });
      return;
    }

    const filteredResults = worldLocation.filter((item) => {
      // Check if the key is not in the excluded fields and a match is found in any field
      for (const key in item) {
        if (
          !this.state.excludedFields.includes(key) &&
          item[key] &&
          item[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }

      // Additional grouping logic
      if (
        (item.Descriptor &&
          item.Descriptor.toLowerCase().includes("airport")) ||
        (item.Address1 && item.Address1.toLowerCase().includes("airport")) ||
        (item.Address2 && item.Address2.toLowerCase().includes("airport"))
      ) {
        item.group = "Airport";
      } else if (
        item["Postal Code"] &&
        item["Postal Code"].toString().trim() !== ""
      ) {
        item.group = "Neighborhood";
      } else {
        item.group = "City";
      }

      return false;
    });

    this.setState({ searchResults: filteredResults });
  };

  handleLocationClick = (selectedLocation) => {
    const descriptor = selectedLocation.Descriptor || "";
    const address1 = selectedLocation.Address1 || "";
    const city = selectedLocation.city || "";
    const mne = selectedLocation.MNE || "";
    document.getElementById("locationValue").value =
      descriptor + " " + address1 + " " + city + " " + mne ?? "";

    localStorage.setItem(
      "locationValue",
      descriptor + " " + address1 + " " + city + " " + mne ?? ""
    );
    localStorage.setItem("countryCodePick", selectedLocation.Country);

    const combinedLocation = `${descriptor} ${address1}, ${city}, ${mne}`;
    console.log("Combined Location:", combinedLocation);
    this.setState({
      location: selectedLocation.MNE,
      locationName: combinedLocation,
      countryPick: selectedLocation.Country,
      searchResults: [],
      selectedLocation: combinedLocation,
    });
  };

  handleLocationClickReturn = (selectedLocation) => {
    console.log("Selected Location:", selectedLocation);
    const descriptor = selectedLocation.Descriptor || "";
    const address1 = selectedLocation.Address1 || "";
    const city = selectedLocation.city || "";
    const mne = selectedLocation.MNE || "";
    document.getElementById("locationReturnValue").value =
      descriptor + " " + address1 + " " + city + " " + mne ?? "";

    this.setState({
      return_location: selectedLocation.MNE,
      returnlocationName: selectedLocation.Descriptor,
      countryPick: selectedLocation.Country,
      returnsearchResults: [],
      selectedLocation: selectedLocation.Descriptor,
    });
  };

  date_change(event) {
    const selectedDate = new Date(event);
    const returnDate = new Date(
      selectedDate.getTime() + 2 * 24 * 60 * 60 * 1000
    );
    this.setState({
      date: event,
      return_date: returnDate,
    });
  }

  pickup_time_change(event) {
    this.setState({
      time: event.target.value,
    });
  }

  return_time_change(event) {
    this.setState({
      return_time: event.target.value,
    });
  }

  return_location_change(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.setState({
      return_location: searchTerm,
    });
    console.log(event.key);
    if (event.key === "Backspace" || event.key === "Delete") {
      // Handle backspace or delete separately
      this.handleBackspaceOrDelete(searchTerm);
      return;
    }

    this.setState({ return_location: searchTerm });

    if (searchTerm.trim() === "") {
      // Clear the search results and selected location when the input is empty
      this.setState({ returnsearchResults: [], selectedLocation: null });
      return;
    }

    const filteredResults = worldLocation.filter((item) => {
      // Check if the key is not in the excluded fields and a match is found in any field
      for (const key in item) {
        if (
          !this.state.excludedFields.includes(key) &&
          item[key] &&
          item[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }

      // Additional grouping logic
      if (
        (item.Descriptor &&
          item.Descriptor.toLowerCase().includes("airport")) ||
        (item.Address1 && item.Address1.toLowerCase().includes("airport")) ||
        (item.Address2 && item.Address2.toLowerCase().includes("airport"))
      ) {
        item.group = "Airport";
      } else if (
        item["Postal Code"] &&
        item["Postal Code"].toString().trim() !== ""
      ) {
        item.group = "Neighborhood";
      } else {
        item.group = "City";
      }

      return false;
    });

    this.setState({ returnsearchResults: filteredResults });
  }

  return_date_change(event) {
    this.setState({
      return_date: event,
    });
  }

  age_change(event) {
    this.setState({
      age: event.target.value,
    });
  }

  country_change(event) {
    this.setState({
      country: event.target.value,
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const time = times();

    return (
      <>
        {/* Start Banner One */}
        <section
          className="banner-one"
          style={{ backgroundImage: "linear-gradient(#be0026, #9b001e)" }}
        >
          <div className="banner-one__inner py-4">
            <div className="container">
              <form onSubmit={(e) => this.submit(e)}>
                <div className="title text-center py-4">
                  <h1 style={{ color: "#fff" }}>
                    FIND YOUR BEST CAR RENTAL WITH BUDGET
                  </h1>
                </div>
                <div
                  className="row mt-3 mb-3 p-2"
                  style={{ backgroundColor: "rgb(255 255 255 / 20%)" }}
                >
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-6 p-0 border-end">
                        <div style={{ position: "relative" }}>
                          <input
                            required
                            type="text"
                            className="form-control input-budget py-3"
                            placeholder="Enter your pick-up location or zip code"
                            id="locationValue"
                            style={{ borderRadius: "0px" }}
                            onChange={(e) => this.location_change(e)}
                          />

                          {this.state.searchResults.length > 0 ? (
                            <div
                              className="live-search bg-light p-2"
                              style={{
                                position: "absolute",
                                zIndex: 1,
                                width: "100%",
                                maxHeight: "300px",
                                overflowY: "auto",
                              }}
                            >
                              {this.groupResultsByGroup().map(
                                (groupedResults, groupIndex) => (
                                  <div key={groupIndex}>
                                    <div
                                      className="d-flex"
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <h5>{groupedResults[0].group}</h5>
                                      <span className="ms-auto">
                                        {groupedResults.length} results
                                      </span>
                                    </div>
                                    {groupedResults.map((result, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          cursor: "pointer",
                                          padding: "5px",
                                        }}
                                      >
                                        <p
                                          id="descriptionLoc"
                                          onClick={() =>
                                            this.handleLocationClick(result)
                                          }
                                        >
                                          <strong className="text-primary">
                                            {result.Descriptor}
                                          </strong>
                                          , {result.Address1 ?? ""}{" "}
                                          {result?.city ?? ""}{" "}
                                          {result["Postal Code"] ?? ""}{" "}
                                          <strong className="text-primary">
                                            {result.MNE ?? ""}
                                          </strong>
                                        </p>

                                        {/* Include other fields you want to display */}
                                      </div>
                                    ))}
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-sm-3 p-0 border-end">
                        <DatePicker
                          wrapperClassName="datepicker"
                          selected={this.state.date || new Date()}
                          onChange={(e) => this.date_change(e)}
                          className="form-control input-budget py-3"
                          dateFormat={"dd-MM-yyyy"}
                          minDate={
                            new Date(
                              new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                            )
                          }
                          value={this.state.date}
                        />
                      </div>
                      <div className="col-sm-3 p-0">
                        <select
                          name=""
                          id=""
                          required
                          className="form-control input-budget py-3"
                          style={{ borderRadius: "0px" }}
                          onChange={(e) => this.pickup_time_change(e)}
                          value={this.state.time}
                        >
                          {time.map((data, i) => (
                            <option value={data.value}>{data.field}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-6 p-0 border-end">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            id="locationReturnValue"
                            className="form-control input-budget py-3"
                            placeholder="Return on same location"
                            style={{ borderRadius: "0px" }}
                            onChange={(e) => this.return_location_change(e)}
                          />

                          {this.state.returnsearchResults.length > 0 ? (
                            <div
                              className="live-search bg-light p-2"
                              style={{
                                position: "absolute",
                                zIndex: 1,
                                width: "100%",
                              }}
                            >
                              {this.groupResultsByGroupReturn().map(
                                (groupedResults, groupIndex) => (
                                  <div key={groupIndex}>
                                    <div
                                      className="d-flex"
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <h5>{groupedResults[0].group}</h5>
                                      <span className="ms-auto">
                                        {groupedResults.length} results
                                      </span>
                                    </div>
                                    {groupedResults.map((result, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          cursor: "pointer",
                                          padding: "5px",
                                        }}
                                      >
                                        <p
                                          id="descriptionLoc"
                                          onClick={() =>
                                            this.handleLocationClickReturn(
                                              result
                                            )
                                          }
                                        >
                                          <strong className="text-primary">
                                            {result.Descriptor}
                                          </strong>
                                          , {result.Address1} {result?.city}{" "}
                                          {result["Postal Code"] ?? ""}{" "}
                                          <strong className="text-primary">
                                            {result.MNE}
                                          </strong>
                                        </p>

                                        {/* Include other fields you want to display */}
                                      </div>
                                    ))}
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="col-sm-3 p-0 border-end customDatePickerWidth"
                        style={{ backgroundColor: "black" }}
                      >
                        <DatePicker
                          wrapperClassName="datepicker"
                          selected={this.state.return_date}
                          onChange={(e) => this.return_date_change(e)}
                          value={this.state.return_date}
                          minDate={
                            new Date(
                              new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                            )
                          }
                          dateFormat={"dd-MM-yyyy"}
                          className="form-control input-budget py-3"
                        />
                      </div>
                      <div className="col-sm-3 p-0">
                        <select
                          name=""
                          id=""
                          required
                          className="form-control input-budget py-3"
                          style={{ borderRadius: "0px" }}
                          onChange={(e) => this.return_time_change(e)}
                          value={this.state.return_time}
                        >
                          {time.map((data, i) => (
                            <option value={data.value}>{data.field}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-2 col-sm-4 p-0">
                        <div className="input-group">
                          <span className="input-group-text">Age</span>
                          <select
                            required
                            className="form-control"
                            style={{ borderRadius: "0px" }}
                            onChange={(e) =>
                              this.setState({ age: e.target.value })
                            }
                          >
                            <option value={"26"} selected>
                              25+
                            </option>
                            <option value={"24"}>24</option>
                            <option value={"23"}>23</option>
                            <option value={"22"}>22</option>
                            <option value={"21"}>21</option>
                            <option value={"20"}>20</option>
                            <option value={"19"}>19</option>
                            <option value={"18"}>18</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4 p-0">
                        <div className="input-group">
                          <span className="input-group-text">Country</span>
                          <select
                            required
                            className="form-control"
                            style={{ borderRadius: "0px" }}
                            onChange={(e) =>
                              this.setState({ country: e.target.value })
                            }
                          >
                            {countries.map((c, index) => (
                              <option
                                key={index}
                                value={c.code}
                                selected={c.name == "Indonesia" ? true : false}
                              >
                                {c.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className='col-lg-2 col-sm-4 p-0'>
                                        <button className='btn btn-light w-100' style={{ borderRadius: "0px" }}>Add Customer ID *</button>
                                    </div> */}
                      <div className="col-lg-2 col-sm-6 p-0 ">
                        <button
                          type="button"
                          className="btn btn-light w-100"
                          style={{ borderRadius: "0px" }}
                          onClick={() =>
                            this.setState({
                              isShowWizardCode: !this.state.isShowWizardCode,
                              isShowOfferCode: false,
                            })
                          }
                        >
                          Budget Wizard Number
                        </button>
                      </div>
                      <div className="col-lg-2 col-sm-6 p-0">
                        <button
                          type="button"
                          className="btn btn-light w-100"
                          style={{ borderRadius: "0px" }}
                          onClick={() =>
                            this.setState({
                              isShowOfferCode: !this.state.isShowOfferCode,
                              isShowWizardCode: false,
                            })
                          }
                        >
                          Offer Codes{" "}
                        </button>
                      </div>
                      <div className="col-lg-2 col-sm-6 p-0">
                        <input
                          className="form-control"
                          placeholder="Vehicle Type *"
                          style={{ borderRadius: "0px" }}
                        />
                      </div>
                      <div className="col-sm-12 pb-3">
                        {/* <div className='row p-5 mx-1' style={{backgroundColor:"#fff"}}>
                                            <div className='col-sm-6'>
                                                <label>Enter your Fastbreak, rapidRez or online ID</label>
                                            </div>
                                            <div className='col-sm-3'>
                                                <input required type="text" placeholder='Fistt Name'/>
                                            </div>
                                            <div className='col-sm-3'>
                                                <input required type="text" placeholder='Last Name'/>
                                            </div>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                  {this.state.isShowOfferCode ? (
                    <div className="container bg-light py-3">
                      <div className="row justify-content-start align-items-center">
                        <div className="col-sm-3">
                          <p className="text-center">Enter a Discount Code</p>
                        </div>
                        <div className="col-sm-3">
                          <input
                            className="form-control"
                            type="text"
                            name="awd_number"
                            onChange={(e) =>
                              this.setState({ awd_number: e.target.value })
                            }
                            placeholder="AWD #"
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            className="form-control"
                            type="text"
                            name="coupon_code"
                            onChange={(e) =>
                              this.setState({ coupon_code: e.target.value })
                            }
                            placeholder="Coupon Code"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.isShowWizardCode ? (
                    <div className="container bg-light py-3">
                      <div className="row justify-content-start align-items-center">
                        <div className="col-sm-4">
                          <p className="text-center">
                            Enter Wizard Number and Last Name
                          </p>
                        </div>
                        <div className="col-sm-3">
                          <input
                            className="form-control"
                            type="text"
                            name="wizard_number"
                            onChange={(e) =>
                              this.setState({ wizard_number: e.target.value })
                            }
                            placeholder="Wizard Number"
                          />
                        </div>
                        <div className="col-sm-3">
                          <input
                            className="form-control"
                            type="text"
                            name="last_name"
                            onChange={(e) =>
                              this.setState({ last_name: e.target.value })
                            }
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="row pt-4 pb-5">
                  <div className="col-sm-12 text-center">
                    <button className="btn btn-budget" type="submit">
                      Select My Car
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* End Banner One */}
      </>
    );
  }
}
