import React from "react";
import CollapseItemAbout from "../../common/collapse/CollapseItemAbout";
import { Link } from "react-router-dom";

const International = () => {
  return (
    <div className="container">
      <CollapseItemAbout
        label="Australia Car Rental"
        text={[
          "Find our lowest rates for Australia car rental reservations at ",
          <Link className="linked">budget.com.au.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Austria Car Rental"
        text={[
          "Enjoy the Alps in a car rented from ",
          <Link className="linked">budget.com.at.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Bahrain Car Rental"
        text={[
          "Visit ",
          <Link className="linked">budgetbahrain.com</Link>,
          " for rental car reservations and low rates in Bahrain.",
        ]}
      />
      <CollapseItemAbout
        label="Belgium Car Rental"
        text={[
          "In ",
          <Link className="linked">English</Link>,
          ", ",
          <Link className="linked">Français</Link>,
          ", or ",
          <Link className="linked">Nederlands</Link>,
          ", for fast car rental reservations in Belguim, visit ",
          <Link className="linked">budget.be.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Bosnia Car Rental"
        text={[
          "In ",
          <Link className="linked">Bosanski</Link>,
          ", ",
          <Link className="linked">English</Link>,
          ", find our lowest rates for Bosnia car rental reservations at ",
          <Link className="linked">budget.ba.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Bosnia Car Rental"
        text={[
          "In ",
          <Link className="linked">Bosanski</Link>,
          ", ",
          <Link className="linked">English</Link>,
          ", find our lowest rates for Bosnia car rental reservations at ",
          <Link className="linked">budget.ba.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Brazil Car Rental"
        text={[
          "Find our lowest rates for Brazil car rental reservations at ",
          <Link className="linked">budget.com.br.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Bulgaria Car Rental"
        text={[
          "In ",
          <Link className="linked">български</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find our lowest rates for Bulgaria car rental reservations at ",
          <Link className="linked">budget.bg.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Canadian Car Rental in French"
        text={[
          "Visit ",
          <Link className="linked">budget.ca</Link>,
          " for low rates and a wide selection of eco-friendly vehicles – PLUS fast online booking in English and ",
          <Link className="linked">French</Link>,
          "! ",
        ]}
      />
      <CollapseItemAbout
        label="Costa Rica Car Rental"
        text={[
          "Check out ",
          <Link className="linked">budget.co.cr</Link>,
          " for fast the best car rental deals in Costa Rica. ",
        ]}
      />
      <CollapseItemAbout
        label="Denmark Car Rental"
        text={[
          "In ",
          <Link className="linked">Dansk</Link>,
          " or ",
          <Link className="linked">English</Link>,
          " , find our lowest rates for Denmark car hire reservations at ",
          <Link className="linked">budget.dk.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="El Salvador Car Rental"
        text={[
          "El Salvador car rental reservations at ",
          <Link className="linked">budget.com.sv.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Estonia Car Hire"
        text={[
          "In ",
          <Link className="linked">Eesti keeles</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find deals for Estonia car hire reservations at ",
          <Link className="linked">budget.ee.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="France Car Hire"
        text={[
          "Find France car hire rates at",
          <Link className="linked">budget.fr.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Germany Car Hire"
        text={[
          "Check out car rental rates for Germany at ",
          <Link className="linked">budget.de.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Grand Cayman Car Rental"
        text={[
          "Check out car rental information for Grand Cayman at ",
          <Link className="linked">budgetcayman.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Greece Car Hire"
        text={[
          "In ",
          <Link className="linked">Ελληνικά</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find deals for Greece car hire reservations at ",
          <Link className="linked">budgetrentacar.gr.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Guadaloupe Car Rental"
        text={[
          "Check out our lowest car rental rates for Guadeloupe at ",
          <Link className="linked">budget-gp.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Guatemala Car Rental"
        text={[
          "In ",
          <Link className="linked">English</Link>,
          " or ",
          <Link className="linked">Español</Link>,
          " find great car rental rates in Guatemala!",
        ]}
      />
      <CollapseItemAbout
        label="Holland Car Hire"
        text={[
          "In ",
          <Link className="linked">Dutch</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Netherlands car hire reservations at ",
          <Link className="linked">budget.nl.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Honduras Car Rental"
        text={[
          "For fast and friendly car rental reservations in Honduras, visit ",
          <Link className="linked">budget.hn.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Iceland Car Hire"
        text={[
          "In ",
          <Link className="linked">Íslenska</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Iceland car hire reservations at ",
          <Link className="linked">budget.is.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Ireland Car Hire"
        text={[
          "For fast and friendly car rental reservations in Ireland, visit ",
          <Link className="linked">budget.ie.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Italy Car Hire"
        text={[
          "In ",
          <Link className="linked">Italiano</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Italy car hire reservations at ",
          <Link className="linked">budgetautonoleggio.it.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Jamaica Car Rental"
        text={[
          "Rent a car in Jamaica at ",
          <Link className="linked">budgetjamaica.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Jordan Car Rental"
        text={[
          "Rent a car in Jordan at ",
          <Link className="linked">budgetjordan.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Latvia Car Hire"
        text={[
          "In Latviski or English, find rates for Latvia car hire reservations at ",
          <Link className="linked">budget.lv.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Lebanon Car Hire"
        text={[
          "Find rates for Lebanon car hire reservations at ",
          <Link className="linked">budgetbeirut.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Luxembourg Car Hire"
        text={[
          "In ",
          <Link className="linked">Français</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Luxembourg car hire reservations at ",
          <Link className="linked">budget.lu.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Malta Car Hire"
        text={[
          "Find car hire rates for Malta at ",
          <Link className="linked">budget.com.mt.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Martinique Car Rental"
        text={[
          "Find car rental deals for Martinique at ",
          <Link className="linked">budget-martinique.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Mexico Car Rental"
        text={[
          "Check out our lowest car rental rates for Mexico at ",
          <Link className="linked">budget.com.mx.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Middle East Car Hire"
        text={[
          "Find car hire rates in the Middle East ",
          <Link className="linked">budget-arabia.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Morocco Car Hire"
        text={[
          "Get car hire rates in Morocco ",
          <Link className="linked">budget.ma.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="New Zealand Car Rental"
        text={[
          "Rent your next Budget car in New Zealand at ",
          <Link className="linked">budget.co.nz.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Norway Car Hire"
        text={[
          "Get Norwegian car hire rates ",
          <Link className="linked">budget.no.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Oman Car Hire"
        text={[
          "Find car hire rates in Oman ",
          <Link className="linked">budgetoman.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Panama Car Rental"
        text={[
          "Your Panama holiday starts when you book low rates at ",
          <Link className="linked">budgetpanama.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Peru Car Rental"
        text={[
          "Before you pack for Peru, get car rental savings at ",
          <Link className="linked">budgetperu.com.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Poland Car Hire"
        text={[
          "In Polski or English, find rates for Poland car hire at ",
          <Link className="linked">budget.pl.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Portugal Car Hire"
        text={[
          "In ",
          <Link className="linked">Portuguese</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Portugal car hire at ",
          <Link className="linked">budget.com.pt.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Romania Car Hire"
        text={[
          "In ",
          <Link className="linked">Română</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Romania car hire at ",
          <Link className="linked">budget.lu.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Russia Car Hire"
        text={[
          "In ",
          <Link className="linked">РУсский</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Russia car hire reservations at ",
          <Link className="linked">budget-russia.ru.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Serbia Car Hire"
        text={[
          "In ",
          <Link className="linked">Srpski</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Russia car hire reservations at ",
          <Link className="linked">budget.rs.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Slovenia Car Hire"
        text={[
          "In ",
          <Link className="linked">Slovenski</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Slovenia car hire reservations at ",
          <Link className="linked">budget.si.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="South Africa Car Hire"
        text={[
          "In South Africa, find car hire rates at ",
          <Link className="linked">budget.ca.za.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Spain Car Hire"
        text={[
          "In ",
          <Link className="linked">Español</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Spain car hire reservations at ",
          <Link className="linked">budget.es.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Suriname Car Rental"
        text={[
          "Find our lowest rates for Suriname car rental reservations in ",
          <Link className="linked">English</Link>,
          " or ",
          <Link className="linked">Dutch</Link>,
          ".",
        ]}
      />
      <CollapseItemAbout
        label="Sweden Car Hire"
        text={[
          "In ",
          <Link className="linked">Svenska</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Sweden car hire at ",
          <Link className="linked">budget.se.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Switzerland Car Hire"
        text={[
          "In ",
          <Link className="linked">Deutsch</Link>,
          " or ",
          <Link className="linked">Français</Link>,
          ", find rates for Switzerland car hire at ",
          <Link className="linked">budget.ch.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Turkey Car Hire"
        text={[
          "In ",
          <Link className="linked">Türkçe</Link>,
          " or ",
          <Link className="linked">English</Link>,
          ", find rates for Turkish car hire at ",
          <Link className="linked">budget.com.tr.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Ukraine Car Hire"
        text={[
          "Whether in ",
          <Link className="linked">РУсский</Link>,
          ", ",
          <Link className="linked">English</Link>,
          " or ",
          <Link className="linked">Украïнська</Link>,
          " find rates for Ukraine car hire at ",
          <Link className="linked">budget.ua.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="United Kingdom Car Rental"
        text={[
          "Rent a car in the UK at ",
          <Link className="linked">budget.co.uk.</Link>,
        ]}
      />
      <CollapseItemAbout
        label="Avis Car Rental"
        text={[
          "Did you know? Avis, Budget’s sister car rental company, offers ",
          <Link className="linked">hot deals</Link>,
          " every day!",
        ]}
      />
      <CollapseItemAbout
        label="Limited-time and Seasonal Deals"
        text={[
          "Check out ",
          <Link className="linked">carrental.com</Link>,
          " every day for U.S., International, One-way and Last-minute Deals. New updates daily!",
        ]}
      />
      <CollapseItemAbout
        label="Government Employee Deals"
        text={[
          "Visit ",
          <Link className="linked">govcars.org</Link>,
          " for exclusive business and leisure car rental savings for federal government employees and U.S. military.",
        ]}
      />
      <CollapseItemAbout
        label="International Car Rental"
        text={[
          "Next time you travel outside the country, book your international car rental ",
          <Link className="linked">right here!</Link>,
        ]}
      />
      <CollapseItemAbout
        label="City Deals and Special Rates"
        text={[
          <Link className="linked">Rentacar.com </Link>,
          " is your 24/7car rental resource for limited-time and seasonal domestic and international deals. ",
        ]}
      />
      <CollapseItemAbout
        label="Travel Agent Reservations"
        text={[
          "Our valued travel agents earn more through our ",
          <Link className="linked">Unlimited Budget loyalty program</Link>,
          ". Get paid on every qualifying booking!",
        ]}
      />
    </div>
  );
};

export default International;
