import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import TravelAgentContent from "../components/travel-agent/TravelAgentContent";
import FooterOne from "../common/footer/FooterOne";
import IATA from "../components/travel-agent/IATA";

const IATACarRental = () => {
  const breadcrumb = [
    "Programs",
    "Travel Agent Program",
    "IATA Car Rental Rates and Reservation",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <IATA />
      <FooterOne />
    </>
  );
};

export default IATACarRental;
