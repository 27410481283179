import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OfferDetails from "../components/additionaldriver/OfferDetails";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";

const AdditonalDriver = () => {
    const breadcrumb = ["Products and Services", "Services", "Additional Driver"];
    return (
        <>
            <HeaderOne />
            <BreadcrumbCar breadcrumb={breadcrumb} />
            <LandingSelectCar
                image=""
                title="Budget Additonal Driver"
                sub="The ultimate in convenience and choice"
            />
            <OfferDetails />
            <FooterOne />
        </>
    );
};

export default AdditonalDriver;
