import React from "react";
import { Link } from "react-router-dom";

export default class CG extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myBg = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/carguides.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myText = {
      color: "#000",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
    };
    return (
      <>
        {/* Start Slogan One */}
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-3">
                <select className="form-control">
                  <option>All Vehicles</option>
                </select>
              </div>
              <div className="col-lg-3">
                <select className="form-control">
                  <option>U.S.A.</option>
                </select>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3 pb-3">
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica or similar{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button style={myLink}>Vehicle Info </button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button
                          className="btn align-self-center"
                          style={{
                            backgroundColor: "rgb(0, 40, 95)",
                            color: "#fff",
                            padding: "0px 50px",
                            height: "50px",
                          }}
                        >
                          Reserve
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica or similar{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button style={myLink}>Vehicle Info </button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button
                          className="btn align-self-center"
                          style={{
                            backgroundColor: "rgb(0, 40, 95)",
                            color: "#fff",
                            padding: "0px 50px",
                            height: "50px",
                          }}
                        >
                          Reserve
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica or similar{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button style={myLink}>Vehicle Info </button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button
                          className="btn align-self-center"
                          style={{
                            backgroundColor: "rgb(0, 40, 95)",
                            color: "#fff",
                            padding: "0px 50px",
                            height: "50px",
                          }}
                        >
                          Reserve
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container pt-3">
            <div className="row">
              <div className="col-sm-12">
                <p
                  className="mb-3"
                  style={{ fontSize: "25px", color: "rgb(0, 40, 95)" }}
                >
                  Budget Car Rental Options
                </p>
                <p className="mb-3" style={myPara}>
                  Find any type of car you want at a great price when you rent
                  from Budget. Whether you’re traveling solo on a last-minute
                  business trip or planning ahead for a much-deserved family
                  vacation, one of our rental car types will suit you perfectly.{" "}
                </p>
                <p className="mb-3" style={myPara}>
                  Our complete rental car list includes:
                </p>
                <ul style={myPara} className="ps-3 mb-3">
                  <li>
                    <Link to={"/van-rental"} style={myLink}>
                      Vans with seating for 7-15
                    </Link>
                  </li>
                  <li>
                    <Link to={"/suv-rental"} style={myLink}>
                      Spacious SUVs of all sizes
                    </Link>
                  </li>
                  <li>
                    <Link to={"/pickup-truck-rent"} style={myLink}>
                      Pickup trucks for bringing gear along
                    </Link>
                  </li>
                  <li>
                    <Link to={"/rent-car-sizes"} style={myLink}>
                      Sedans from economy to deluxe
                    </Link>
                  </li>
                </ul>
                <p className="mb-3" style={myPara}>
                  Want to tour around in something special? Check out our{" "}
                  <Link to={"/sweeter-rides"} style={myLink}>
                    popular rentals and luxury vehicles
                  </Link>
                  ! Take a car with amazing gas mileage on your dream road trip,
                  or take your dream car out for a special weekend getaway. The
                  many Budget rental car classes give you plenty of ways to
                  travel.{" "}
                </p>
                <p className="mb-3" style={myPara}>
                  Available rental car classes will vary by location, as well as
                  when you pick up. The sooner you book, the more choices you’ll
                  have, so don’t wait. Reserve a Budget car rental online today.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Slogan One */}
      </>
    );
  }
}
