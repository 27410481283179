import React from 'react';
import { Link } from 'react-router-dom';

export default class Fa extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        const myStyle = {
            backgroundImage: "url(" + publicUrl + "assets/images/resources/productservice.avif)",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: "250px"
        };
        const myLink = {
            color: "rgb(237, 85, 5)",
        };
        const myPara = {
            lineHeight: "1.5",
            fontSize: "15px",
            color: "#000"
        };
        const myHead = {
            fontSize: "18px"
        };
        const myCard = {
            backgroundColor: "#fff"
        };
        const myParas = {
            lineHeight: "1.5",
            height: "150px",
            fontSize: "15px",
            color: "#000"
        }
        return (
            <>
                <section className='border-top border-bottom'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-12 pt-2'>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item"><a href="#">Customer Service</a></li>
                                        <li className="breadcrumb-item myLink" aria-current="page">FAQ</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={myStyle}>
                    <div className='container pt-5'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h1>Budget Car Rental FAQs</h1>
                                <p >Useful Information about Budget Rent a Car</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{ backgroundColor: "#eaeaea" }}>
                    <div className='container'>
                        <div className='row pt-3'>
                            <div className='col-sm-12'>
                                <p style={myPara}>We've put together our frequently asked questions to help you understand Budget's quick and friendly car rental process. If your question is not answered here, please contact us.</p>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <div className='col-sm-3'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='btnCar my-2'>Rental Requirement</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Reservation</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Cancellation</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Fee & Taxes</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Vehicle Usage Infromation</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Bussiness Office</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Select Payment Method</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <button className='my-2'>Rental Service</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-9 py-2' style={{ backgroundColor: '#fff' }}>
                                <p className='mb-3' style={{ fontSize: "25px", color: "rgb(0, 40, 95)" }}>Rental Requirements</p>
                                <p className='mb-3' style={{ fontSize: "25px", color: "#000" }}>Qualification for Car Hire</p>
                                <p className='mb-3' style={myLink}>What are the requirements for booking or renting a car on Budget? </p>
                                <p className='mb-3' style={myLink}>Driver Age Requirements</p>

                                <p className='mb-3' style={{ fontSize: "25px", color: "#000" }}>Terms</p>
                                <p className='mb-3' style={myLink}>Are there any special terms and conditions for car rental?</p>
                                <p className='mb-3' style={myLink}>Where can I check the terms and conditions of the rental location? </p>
                                <p className='mb-3' style={myLink}>Rental Agreement </p>

                                <p className='mb-3' style={{ fontSize: "25px", color: "#000" }}>Deposit</p>
                                <p className='mb-3' style={myLink}>Is a deposit required when renting a car? </p>

                                <p className='mb-3' style={{ fontSize: "25px", color: "#000" }}>Additional Driver</p>
                                <p className='mb-3' style={myLink}>Can additional drivers be registered? </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}