export const times = () => {
  return [
    { field: "12:00 AM", value: "12:00 AM" },
    { field: "12:30 AM", value: "12:30 AM" },
    { field: "01:00 AM", value: "01:00 AM" },
    { field: "01:30 AM", value: "01:30 AM" },
    { field: "02:00 AM", value: "02:00 AM" },
    { field: "02:30 AM", value: "02:30 AM" },
    { field: "03:00 AM", value: "03:00 AM" },
    { field: "03:30 AM", value: "03:30 AM" },
    { field: "04:00 AM", value: "04:00 AM" },
    { field: "04:30 AM", value: "04:30 AM" },
    { field: "05:00 AM", value: "05:00 AM" },
    { field: "05:30 AM", value: "05:30 AM" },
    { field: "06:00 AM", value: "06:00 AM" },
    { field: "06:30 AM", value: "06:30 AM" },
    { field: "07:00 AM", value: "07:00 AM" },
    { field: "07:30 AM", value: "07:30 AM" },
    { field: "08:00 AM", value: "08:00 AM" },
    { field: "08:30 AM", value: "08:30 AM" },
    { field: "09:00 AM", value: "09:00 AM" },
    { field: "09:30 AM", value: "09:30 AM" },
    { field: "10:00 AM", value: "10:00 AM" },
    { field: "10:30 AM", value: "10:30 AM" },
    { field: "11:00 AM", value: "11:00 AM" },
    { field: "11:30 AM", value: "11:30 AM" },
    { field: "12:30 PM", value: "12:30 PM" },
    { field: "01:00 PM", value: "01:00 PM" },
    { field: "01:30 PM", value: "01:30 PM" },
    { field: "02:00 PM", value: "02:00 PM" },
    { field: "02:30 PM", value: "02:30 PM" },
    { field: "03:00 PM", value: "03:00 PM" },
    { field: "03:30 PM", value: "03:30 PM" },
    { field: "04:00 PM", value: "04:00 PM" },
    { field: "04:30 PM", value: "04:30 PM" },
    { field: "05:00 PM", value: "05:00 PM" },
    { field: "05:30 PM", value: "05:30 PM" },
    { field: "06:00 PM", value: "06:00 PM" },
    { field: "06:30 PM", value: "06:30 PM" },
    { field: "07:00 PM", value: "07:00 PM" },
    { field: "07:30 PM", value: "07:30 PM" },
    { field: "08:00 PM", value: "08:00 PM" },
    { field: "08:30 PM", value: "08:30 PM" },
    { field: "09:00 PM", value: "09:00 PM" },
    { field: "09:30 PM", value: "09:30 PM" },
    { field: "10:00 PM", value: "10:00 PM" },
    { field: "10:30 PM", value: "10:30 PM" },
    { field: "11:00 PM", value: "11:00 PM" },
    { field: "11:30 PM", value: "11:30 PM" },
    { field: "12:00 PM", value: "12:00 PM" },
  ];
};
