import React from "react";

const OfferDetails = () => {
    return (
        <div className="container">
            <hr />
            <h3>Budget Worry-free Promise</h3>
            <p className="mt-3 mb-3">
                The assurance that we’ve taken countless safety measures to make the experience safe for everyone.
            </p>
            <b className="mt-3">A safe journey with Budget</b>
            <p className="mt-3 mb-3">
                Nothing is more important than the health and safety of our customers and employees. It is a paramount and ongoing commitment from Budget.
            </p>
            <p className="mt-3 mb-3">
                Our safety policies extend into all aspects of your rental experience, from the quality of our well-maintained fleet, through to ensuring we protect your data and optimise your digital experience, while making sure our rental locations are clean, safe spaces and your vehicle has been thoroughly cleaned.
            </p>
            <p className="mt-3 mb-3">
                We're here to continue to deliver on the trust you place in us all, confident that every one of our employees is working diligently to provide the highest levels of safety.
            </p>
            <b className="mt-3">Our rental stations</b>
            <ul>
                <li>•	We continue to take every step we can to create a clean and safe environment. This means listening to government travel advice and ensuring our processes comply with all legal requirements.</li>
                <li>•	Counters and hard surfaces within our rental locations continue to be cleaned at regular intervals</li>
                <li>•	Hand sanitiser is available</li>
                <li>•	Our employees are required to wash their hands frequently and have training to high standards in Health & Safety and regulation guidelines, including on products and processes available to ensure your safety.</li>
            </ul>
            <b className="mt-3">Your vehicle</b>
            <ul>
                <li>•	Whenever you travel with Budget, we want you to know that we are here for you, and that every one of our employees is working diligently to provide the highest levels of car rental safety in our well-maintained vehicles. To ensure your continued safety, each vehicle is carefully cleaned before every rental.  </li>
                <li>•	Our rental vehicles are thoroughly prepared for the road before each rental: that means tyres are checked, along with oil and coolant levels, lights and windscreen wipers.</li>
                <li>•	Special attention is paid to steering wheels, indicators, dashboards, hand grips, in-car entertainment controls, cup holders, centre consoles, and all door handles both inside and out.</li>
            </ul>
        </div>
    );
};

export default OfferDetails;
