import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Header from "../common/header/Header";
import ContentAbout from "../components/about/ContentAbout";
import Title from "../Title";

const About = () => {
  Title("About");
  return (
    <>
      <HeaderOne />
      <Header
        title="About Budget Rent a Car"
        subtitle="Renting great cars to value-minded customers since 1958"
      />
      <ContentAbout />
      <FooterOne />
    </>
  );
};

export default About;
