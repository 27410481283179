import React from "react";
import { Link } from "react-router-dom";
import Box from "./Box";

const BestDeals = () => {
  return (
    <div className="bg-blue p-5">
      <div className="container">
        <h5 className="text-white text-center">
          Our best deals for AARP members
        </h5>
        <div className="row justify-content-center py-3">
          <Box
            class="orange"
            title="$10 Off"
            subtitle="When you spend $175 or more"
          />
          <Box class="white" title="Up to 35% Off Pay Now" subtitle="" />
          <Box
            class="lightblue"
            title="SUV Offer"
            subtitle="Rates as low as $59/day!"
          />
        </div>
      </div>
    </div>
  );
};

export default BestDeals;
