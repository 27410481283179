import React, { useState } from "react";

const OptionHeader = () => {
  return (
    <section
      style={{
        backgroundColor: "rgb(247, 139, 0)",
        position: "sticky",
        top: "80px",
        left: "0",
        right: "0",
        zIndex: "99",
      }}
    >
      <div className="container pb-3">
        <div className="row">
          <div className="col-sm-4 pt-3 pe-0">
            <input
              type="text"
              className="form-control"
              placeholder="Enter your pick-up location or zip code"
            />
          </div>
          <div className="col-sm-3 pt-3 ps-1 pe-0">
            <input type="date" className="form-control" />
          </div>
          <div className="col-sm-3 pt-3 pe-0 ps-1">
            <input type="time" className="form-control" />
          </div>
          <div className="col-sm-2 ps-1 pt-3">
            <button
              className="btn btn-primary form-control"
              type="submit"
              style={{ backgroundColor: "#00285f" }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OptionHeader;
