import React from "react";
import Header from "../../common/header/Header";
import { Link } from "react-router-dom";

const AirsDetails = (props) => {
  return (
    <>
      <Header title={props.obj.title} />
      <div className="container">
        {props.obj.img == "" ? (
          ""
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/assets/cars-budget/${props.obj.img}`}
          />
        )}
        {props.obj.description}

        {props.obj.rewards == "" ? (
          ""
        ) : (
          <>
            {" "}
            <p className="mb-3">
              <b className="text-dark">Program Rewards</b>
            </p>
            {props.obj.rewards}
          </>
        )}
        <p className="mb-3">
          <b className="text-dark">Terms and Conditions</b>
        </p>
        {props.obj.terms}
        {props.obj.tax == "" ? (
          ""
        ) : (
          <>
            <p className="mb-3">
              <b className="text-dark">Frequent Flyer Tax</b>
            </p>
            {props.obj.tax}
          </>
        )}
        {props.obj.partner == "" ? (
          ""
        ) : (
          <>
            <p className="mb-3">
              <b className="text-dark">Partner Information</b>
            </p>
            {props.obj.partner}
          </>
        )}
        {props.obj.notes}
      </div>
    </>
  );
};

export default AirsDetails;
